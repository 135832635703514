import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { importationActions, importationSelectors } from '../+state/importation';
import { ListParams } from '@angular-monorepo/shared/util-utils';
import { Importation } from '../entities';
import { ImportationServive } from '../infrastructure/importation.service';

@Injectable({ providedIn: 'root' })
export class ImportationListFacade {
  paginator$ = this.store.select(importationSelectors.selectPagination);
  importations$ = this.store.select(importationSelectors.selectImportationAll);
  constructor(private store: Store, private importationService: ImportationServive) {}

  loadPaginate(params?: ListParams) {
    this.store.dispatch(importationActions.loadWithPagination({ params }));
  }

  downloadOriginalFile(entity: Importation) {
    this.importationService.downloadOriginalFile(entity);
  }

  downloadCommentFile(entity: Importation) {
    this.importationService.downloadCommentFile(entity);
  }

  downloadSuccessFile(entity: Importation) {
    this.importationService.downloadSuccessFile(entity);
  }

  downloadErrorFile(entity: Importation) {
    this.importationService.downloadErrorFile(entity);
  }
}
