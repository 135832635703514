import { Category } from '../../entities/category';
import { createAction, props } from '@ngrx/store';
import {
  createFailureAction,
  createSuccessAction,
  ListParams,
  Paginate,
  SerializableHttpErrorResponse,
} from '@angular-monorepo/shared/util-utils';

export const categoryActions = {
  load: createAction('[Category/API] Load Categorys', props<{ params?: ListParams }>()),
  loadSuccess: createAction('[Category/API] Load Categorys Success', props<{ entities: Category[] }>()),
  loadFailure: createAction('[Category/API] Load Categorys Failure', props<{ error: SerializableHttpErrorResponse }>()),

  loadWithPagination: createAction('[Category/API] Load with Pagination Categorys', props<{ params?: ListParams }>()),
  loadWithPaginationSuccess: createAction(
    '[Category/API] Load with Pagination Categorys Success',
    props<{ paginateEntities: Paginate<Category[]> }>()
  ),
  loadWithPaginationFailure: createAction(
    '[Category/API] Load with Pagination Categorys Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),

  add: createAction('[Category/API] Add Category', props<{ entity: Category }>()),
  addSuccess: createSuccessAction('[Category/API] Add Category Success', props<{ entity: Category }>(), {
    title: 'Category created successfully',
  }),
  addFailure: createFailureAction(
    '[Category/API] Add Category Failure',
    props<{ error: SerializableHttpErrorResponse }>(),
    { title: 'Failed to create category' }
  ),

  update: createAction('[Category/API] Update Category', props<{ entity: Category }>()),
  updateSuccess: createSuccessAction('[Category/API] Update Category Success', props<{ entity: Category }>(), {
    title: 'Category updated successfully',
  }),
  updateFailure: createFailureAction(
    '[Category/API] Update Category Failure',
    props<{ error: SerializableHttpErrorResponse }>(),
    { title: 'Failed to update category' }
  ),

  delete: createAction('[Category/API] Delete Category', props<{ entity: Category }>()),
  deleteSuccess: createSuccessAction('[Category/API] Delete Category Success', props<{ path: string }>(), {
    title: 'Category deleted successfully',
  }),
  deleteFailure: createFailureAction(
    '[Category/API] Delete Category Failure',
    props<{ error: SerializableHttpErrorResponse }>(),
    { title: 'Failed to delete category' }
  ),

  get: createAction('[Category/API] Get Category', props<{ id: number }>()),
  getSuccess: createAction('[Category/API] Get Category Success', props<{ entity: Category }>()),
  getFailure: createAction('[Category/API] Get Category Failure', props<{ error: SerializableHttpErrorResponse }>()),

  selectEntity: createAction('[Category/API] Select Category', props<{ id: string | number }>()),
};
