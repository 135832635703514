import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, filter, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { categoryActions } from './category.actions';
import { Injectable } from '@angular/core';
import { CategoryServive } from '../../infrastructure/category.service';
import { SerializableHttpErrorResponse } from '@angular-monorepo/shared/util-utils';
import { userSelectors } from '@angular-monorepo/authentication/domain';

@Injectable()
export class CategoryStoreEffects {
  load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActions.load),
      concatLatestFrom(() => this.store.select(userSelectors.selectUser).pipe(filter((user) => !!user?.partner_id))),
      mergeMap(([action, user]) => {
        const params = user?.partner_id ? { partner: user.partner_id } : undefined;
        return this.entityService.list(params).pipe(
          map((entities) => categoryActions.loadSuccess({ entities })),
          catchError((error: SerializableHttpErrorResponse) => of(categoryActions.loadFailure({ error })))
        );
      })
    );
  });

  loadWithPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActions.loadWithPagination),
      exhaustMap((action) =>
        this.entityService.listWithPagination(action.params).pipe(
          map((paginateEntities) => categoryActions.loadWithPaginationSuccess({ paginateEntities })),
          catchError((error: SerializableHttpErrorResponse) => of(categoryActions.loadWithPaginationFailure({ error })))
        )
      )
    );
  });

  add$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActions.add),
      mergeMap((action) =>
        this.entityService.create(action.entity).pipe(
          map((entity) => categoryActions.addSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(categoryActions.addFailure({ error })))
        )
      )
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActions.update),
      concatMap((action) =>
        this.entityService.update(action.entity).pipe(
          map((entity) => categoryActions.updateSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(categoryActions.updateFailure({ error })))
        )
      )
    );
  });

  delete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActions.delete),
      mergeMap(({ entity }) =>
        this.entityService.delete(entity.id).pipe(
          map(() => categoryActions.deleteSuccess({ path: entity.path })),
          catchError((error: SerializableHttpErrorResponse) => of(categoryActions.deleteFailure({ error })))
        )
      )
    );
  });

  get$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActions.get),
      mergeMap((action) =>
        this.entityService.get(action.id).pipe(
          map((entity) => categoryActions.getSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(categoryActions.getFailure({ error })))
        )
      )
    );
  });
  /*  notifySucces$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        categoryActions.addSuccess,
        categoryActions.updateSuccess,
        categoryActions.deleteSuccess
      ),
      mergeMap((action) =>
        of(action)
      )
    );
  });
  notifyError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        categoryActions.addFailure,
        categoryActions.updateFailure,
        categoryActions.deleteFailure
      ),
      mergeMap((action) =>
        of(action)
      )
    );
  });
*/
  constructor(protected actions$: Actions, protected entityService: CategoryServive, protected store: Store) {}
}
