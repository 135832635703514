import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@angular-monorepo/shared/util-utils';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [],
  exports: [],
})
export class NotificationsFeatureNotificationsModule {}
