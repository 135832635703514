import { ActionCreatorProps, createAction, NotAllowedCheck, props } from '@ngrx/store';
import { Notification } from 'angular2-notifications/lib/interfaces/notification.type';
import { NotificationType } from 'angular2-notifications';
import { SerializableHttpErrorResponse } from './shared.models';

export type AdditionalNotificationParams = {
  notification?: Partial<Notification> | null;
  error?: SerializableHttpErrorResponse;
};

export function createSuccessAction<T extends string, P extends object>(
  type: T,
  config?: ActionCreatorProps<P> & NotAllowedCheck<P>,
  notification?: Partial<Notification>
) {
  const n = {
    type: NotificationType.Success,
    title: 'Succès',
    timeOut: 3000,
    clickToClose: true,
    clickIconToClose: true,
    ...notification,
  };
  return createNotifiableAction(type, config ?? props<any>(), n);
}

export function createFailureAction<T extends string, P extends object & { error: any }>(
  type: T,
  config?: ActionCreatorProps<P> & NotAllowedCheck<P>,
  notification?: Partial<Notification>
) {
  return createNotifiableAction(type, config, {
    type: NotificationType.Error,
    title: 'Erreur',
    icon: 'error',
    timeOut: 30_000,
    clickToClose: true,
    clickIconToClose: true,
    ...notification,
  });
}

export function createNotifiableAction<T extends string, P extends object>(
  type: T,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  config?: ActionCreatorProps<P> & NotAllowedCheck<P>,
  notification?: Partial<Notification>
) {
  return createAction<
    T,
    // [args: P | P & AdditionalNotificationParams],
    [args?: P & AdditionalNotificationParams],
    P & AdditionalNotificationParams & NotAllowedCheck<P & AdditionalNotificationParams>
  >(type, (args) => {
    const notificationParams =
      args?.notification === null
        ? undefined
        : {
            notification: {
              ...notification,
              ...args?.notification,
            },
          };
    return {
      ...args,
      ...notificationParams,
    } as P & AdditionalNotificationParams & NotAllowedCheck<P & AdditionalNotificationParams>;
  });
}
