import { Actions, createEffect } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { AdditionalNotificationParams } from '@angular-monorepo/shared/util-utils';
import { NotificationUiFacade } from '../../application/notification-ui.facade';

export type NotificationAction = Action & AdditionalNotificationParams;

@Injectable()
export class NotificationsEffects {
  openNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        filter((action) => {
          return !!action.notification;
        }),
        tap((action) => {
          let message = action?.notification?.content ?? '';
          const title = action.notification?.title;
          const context = {} as any;
          if (action.error) {
            if (action.error.statusCode === 500) {
              message = 'Unexpected error from server. Our technical team was informed';
            } else {
              message = this.notificationFacade.contentTemplate;
              let details = action.error.error.detail ?? action.error.error;
              if (typeof details === 'string') {
                try {
                  details = JSON.parse(details);
                } catch (e) {
                  message = details;
                }
              }
              context.json_error = details;
            }
          }
          this._notifications.create(
            title,
            message,
            // this.notificationFacade.contentTemplate,
            action.notification?.type ?? NotificationType.Bare,
            action.notification,
            context
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<NotificationAction>,
    private snackbar: MatSnackBar,
    private _notifications: NotificationsService,
    private notificationFacade: NotificationUiFacade
  ) {}
}
