import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDefaultTypeDirective } from './button-default-type.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ButtonDefaultTypeDirective],
  exports: [CommonModule, ButtonDefaultTypeDirective],
})
export class SharedModule {}
