import { createAction, props } from '@ngrx/store';
import { createFailureAction, SerializableHttpErrorResponse } from '@angular-monorepo/shared/util-utils';
import { User } from '../../entities/user';

export const userActions = {
  update: createAction('[User/API] Update User', props<{ entity: User }>()),

  updateSuccess: createAction('[User/API] Update User Success', props<{ entity: User }>()),

  updateFailure: createAction('[User/API] Update User Failure', props<{ error: SerializableHttpErrorResponse }>()),

  get: createAction('[User/API] Get User', props<{ id: number }>()),

  getSuccess: createAction('[User/API] Get User Success', props<{ entity: User }>()),

  getFailure: createFailureAction('[User/API] Get User Failure', props<{ error: SerializableHttpErrorResponse }>()),
  selectEntity: createAction('[User/API] Select User', props<{ id: string | number }>()),
  updateEmail: createAction('[User/API] Update Email User', props<{ email: string }>()),
  updatePassword: createAction(
    '[User/API] Update Password User',
    props<{
      new_password: string;
      re_new_password: string;
      current_password: string;
    }>()
  ),
};
