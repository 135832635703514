import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, categoryFeatureKey, CategoryState } from './category.reducer';
import { attributesSelectors } from '@angular-monorepo/attributes/domain';
import { Category, CategoryNode } from '../../entities/category';
import { Dictionary } from '@ngrx/entity';
import { routerSelectors } from '@angular-monorepo/shared/util-utils';

const { selectIds, selectAll, selectEntities, selectTotal } = adapter.getSelectors();

const selectFeatureSelector = createFeatureSelector<CategoryState>(categoryFeatureKey);

const selectState = createSelector(selectFeatureSelector, (state) => state);

const selectCategoryIds = createSelector(selectState, selectIds);
const selectCategoryAll = createSelector(selectState, selectAll);
const selectCategoryTotal = createSelector(selectState, selectTotal);

const selectCategoryEntities = createSelector(selectState, selectEntities);
const selectCategoryLoading = createSelector(selectState, (state) => state.loading);

const selectPagination = createSelector(selectFeatureSelector, (state) => ({
  next: state.next,
  previous: state.previous,
  count: state.count,
}));
const selectSelectedEntityId = createSelector(selectFeatureSelector, (state) => state.selectedEntityId);

const selectSelectedEntity = createSelector(
  selectCategoryEntities,
  selectSelectedEntityId,
  (entities, selectedEntityId) => {
    if (selectedEntityId) {
      return entities[selectedEntityId];
    } else {
      return undefined;
    }
  }
);

type CategoryNodeEntity = Omit<CategoryNode, 'children' | 'parent'> & {
  children: { [key: string]: CategoryNodeEntity };
  parent: CategoryNodeEntity;
};

const selectTreeCategoryNode = createSelector(selectCategoryEntities, selectCategoryAll, (entities, entitiesAll) => {
  const nodePointers = {} as { [path: string]: CategoryNodeEntity };
  const tree = {
    children: {},
    category: null as unknown as Category,
    isRoot: true,
    isExpanded: false,
    parent: null as unknown as CategoryNodeEntity,
  } as CategoryNodeEntity;

  entitiesAll.forEach((entity) => {
    const node = {
      children: {},
      category: entity,
      isRoot: false,
      isExpanded: false,
    } as CategoryNodeEntity;
    nodePointers[entity.path] = node;
  });
  entitiesAll.forEach((entity) => {
    const parentPath = entity.path.slice(0, -4);
    const parentNode = nodePointers[parentPath];

    if (parentNode) {
      parentNode.children[entity.path] = nodePointers[entity.path];
    } else {
      tree.children[entity.path] = nodePointers[entity.path];
    }
    nodePointers[entity.path].parent = parentNode;
  });
  const transform = (node: CategoryNodeEntity, parent: CategoryNode): CategoryNode => {
    const ret = {
      ...node,
      parent,
    } as unknown as CategoryNode;
    ret.children = Object.keys(node.children).map((key) => transform(node.children[key], ret));
    return ret;
  };
  const root = transform(tree, null as unknown as CategoryNode);
  // console.log('root', root);
  return root.children.map((node) => {
    node.parent = undefined;
    return node;
  });
});

const selectCategoryNodeById = createSelector(selectTreeCategoryNode, (categoriesNodes) => {
  const ret = {} as Dictionary<CategoryNode>;
  const traverse = (node: CategoryNode) => {
    ret[node.category.id] = node;
    for (const child of node.children) {
      traverse(child);
    }
  };
  for (const child of categoriesNodes) {
    traverse(child);
  }
  return ret;
});
// const selectCategoryHumanPath = createSelector(selectTreeCategoryNode, (nodes) => {
//   const cache: { [path: string]: { humanPath: string; node: CategoryNode } } = {};
//   const buildPath = (node: CategoryNode, currentHumanPath: string) => {
//     const categoryPath = node.category?.path ?? '';
//     let humanPath = node.category?.name ?? '';
//     if (currentHumanPath) {
//       humanPath = `${currentHumanPath} > ${humanPath}`;
//     }
//     cache[categoryPath] = {
//       humanPath,
//       node,
//     };
//     for (const child of node.children) {
//       buildPath(child, humanPath);
//     }
//   };
//   for (const node of nodes) {
//     buildPath(node, '');
//   }
//   return cache;
//   // return nodes.map(node => buildPath(node, ''));
// });
const selectRouteCategoryId = routerSelectors.selectRouteNestedParam('categoryId');
// const selectCategoryMode = routerSelectors.selectQueryParam('mode');

// const selectRouteCategory = createSelector(
//   selectCategoryAll,
//   routerSelectors.selectRouteParams,
//   (categories, { categoryId }) => {
//     const id = Number(categoryId);
//     return categories.find((category) => category.id == id);
//   }
// );

const selectCategoryById = (categoryId: number) => {
  return createSelector(selectCategoryAll, (categoryEntities) => {
    // console.log('in selector', cate);
    const id = Number(categoryId);
    return categoryEntities.find((category) => category.id == id);
  });
};

const selectCategoryAttributes = (categoryId: number) => {
  return createSelector(
    selectCategoryById(categoryId),
    attributesSelectors.selectAttributesEntities,
    (category, attributes) => {
      return category?.attributes?.map((attributeId) => attributes[attributeId]);
    }
  );
};

export const categorySelectors = {
  selectCategoryIds,
  selectCategoryAll,
  selectCategoryTotal,
  selectCategoryEntities,
  selectCategoryLoading,
  selectPagination,
  selectSelectedEntityId,
  selectSelectedEntity,
  selectTreeCategoryNode,
  // selectRouteCategory,
  selectRouteCategoryId,
  // selectCategoryMode,
  selectCategoryById,
  selectCategoryAttributes,
  selectCategoryNodeById,
  // selectCategoryHumanPath,
};
