import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../../entities/user';
import { userActions } from './user.actions';

export const userFeatureKey = 'user';

export interface UserState {
  loading: boolean;
  user?: User;
}

const initialState: UserState = {
  loading: false,
  user: undefined,
};

const entitiesReducer = createReducer(
  initialState,
  // begging action
  on(userActions.update, userActions.get, (state) => ({
    ...state,
    loading: true,
  })),
  // error action
  on(userActions.getFailure, userActions.updateFailure, (state, { error }) => ({
    ...state,
    loading: false,
  })),

  on(userActions.getSuccess, (state, { entity }) => {
    return {
      ...state,
      user: { ...entity },
      loading: false,
    };
  }),
  on(userActions.updateSuccess, (state, { entity }) => {
    return {
      ...state,
      user: { ...entity },
      loading: false,
    };
  })
);

export function userReducer(
  state: UserState = initialState,
  action: Action
): UserState {
  return entitiesReducer(state, action);
}
