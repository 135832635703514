import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { Store } from '@ngrx/store';
import { AuthenticationFacade, userActions } from '@angular-monorepo/authentication/domain';
import { PartnerFacade } from './partner.facade';

@Injectable({ providedIn: 'root' })
export class EditAccountFacade {
  user$ = this.authenticationFacade.user$;

  constructor(
    private dialog: MatDialog,
    private store: Store,
    private authenticationFacade: AuthenticationFacade,
    private partnerFacade: PartnerFacade
  ) {
    this.user$.subscribe((user) => this.partnerFacade.getPartner(user?.partner_id));
  }

  showDialog<T>(
    component: ComponentType<T> | TemplateRef<T>,
    config: MatDialogConfig = {
      height: '476px',
      width: '440px',
    }
  ) {
    this.dialog.open(component, config);
  }

  changeEmail(email: string) {
    this.store.dispatch(userActions.updateEmail({ email: email }));
  }

  changePassword(data: { new_password: string; re_new_password: string; current_password: string }) {
    this.store.dispatch(userActions.updatePassword(data));
  }
}
