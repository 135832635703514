import { Directive, Output, Input, EventEmitter, HostBinding, HostListener, NgModule } from '@angular/core';
import { FileValueAccessorDirective } from './file-value-accessor.directive';
import { CommonModule } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Directive({
  selector: '[dropFile]',
})
export class DropFileDirective {
  @Input() multiple = true;

  @HostBinding('class.fileover') fileOver: boolean = false;
  @Output() fileDropped = new EventEmitter<any>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      if (files.length > 1 && !this.multiple) {
        this._snackBar.open('Only one file allowed');
        return;
      }
      this.fileDropped.emit(files);
    }
  }

  constructor(private _snackBar: MatSnackBar) {}
}

@NgModule({
  declarations: [DropFileDirective, FileValueAccessorDirective],
  exports: [DropFileDirective, FileValueAccessorDirective],
})
export class DropFileModule {}
