import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { partnerActions } from './partner.actions';
import { Injectable } from '@angular/core';
import { PartnerServive } from '../../infrastructure/partner.service';
import { SerializableHttpErrorResponse } from '@angular-monorepo/shared/util-utils';
import * as partnerSelectors from './partner.selectors';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class PartnerStoreEffects {
  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(partnerActions.update),
      concatMap((action) =>
        this.entityService.update(action.entity).pipe(
          map((entity) => partnerActions.updateSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(partnerActions.updateFailure({ error })))
        )
      )
    );
  });

  get$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(partnerActions.get),
      switchMap((action) =>
        this.entityService.get(action.id).pipe(
          map((entity) => partnerActions.getSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(partnerActions.getFailure({ error })))
        )
      )
    );
  });
  generateApiKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(partnerActions.generateApiKey),
      concatLatestFrom(() => this.store.select(partnerSelectors.selectPartner)),
      switchMap(([action, partner]) =>
        this.entityService.generateKey(partner.id, action.expiry_date).pipe(
          map((object) => partnerActions.generateApiKeySuccess({ object })),
          catchError((error: SerializableHttpErrorResponse) => of(partnerActions.getFailure({ error })))
        )
      )
    );
  });

  revokeOldApiKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(partnerActions.revokeOldApiKey),
      concatLatestFrom(() => this.store.select(partnerSelectors.selectPartner)),
      concatLatestFrom(() => this.store.select(partnerSelectors.selectOldApiKey)),
      switchMap(([[action, partner], apiKey]) => {
        if (!apiKey) {
          this._notifications.info('Apikey already revoked');
          return of(partnerActions.revokeOldApiKeySuccess());
        }
        return this.entityService.revokeOldGenerateKey(partner?.id, apiKey?.prefix).pipe(
          map(() => partnerActions.revokeOldApiKeySuccess()),
          catchError((error: SerializableHttpErrorResponse) => of(partnerActions.revokeOldApiKeyFailure({ error })))
        );
      })
    );
  });
  redirectToStripe = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(partnerActions.redirectToStripe),

        map(({ url }) => {
          this.entityService.redirectToStripe(url).subscribe((result) => window.open(result, '_blank'));
        })
      );
    },
    {
      dispatch: false,
    }
  );

  constructor(
    protected actions$: Actions,
    protected entityService: PartnerServive,
    protected store: Store,
    private _notifications: NotificationsService
  ) {}
}
