import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { importationActions } from './importation.actions';
import { Injectable } from '@angular/core';
import { ImportationServive } from '../../infrastructure/importation.service';
import { SerializableHttpErrorResponse } from '@angular-monorepo/shared/util-utils';
import { NotificationUiFacade } from '@angular-monorepo/notifications/domain';

@Injectable()
export class ImportationStoreEffects {
  load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importationActions.load),
      exhaustMap((action) =>
        this.entityService.list(action.params).pipe(
          map((entities) => importationActions.loadSuccess({ entities })),
          catchError((error: SerializableHttpErrorResponse) => of(importationActions.loadFailure({ error })))
        )
      )
    );
  });

  loadWithPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importationActions.loadWithPagination),
      exhaustMap((action) =>
        this.entityService.listWithPagination(action.params).pipe(
          map((paginateEntities) => importationActions.loadWithPaginationSuccess({ paginateEntities })),
          catchError((error: SerializableHttpErrorResponse) =>
            of(importationActions.loadWithPaginationFailure({ error }))
          )
        )
      )
    );
  });

  add$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importationActions.add),
      mergeMap((action) =>
        this.entityService.create(action.entity).pipe(
          map((entity) => importationActions.addSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(importationActions.addFailure({ error })))
        )
      )
    );
  });

  get$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importationActions.get),
      switchMap((action) =>
        this.entityService.get(action.id).pipe(
          map((entity) => importationActions.getSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(importationActions.getFailure({ error })))
        )
      )
    );
  });
  constructor(
    protected actions$: Actions,
    protected entityService: ImportationServive,
    protected store: Store,
    private notification: NotificationUiFacade
  ) {}
}
