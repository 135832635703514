import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationStoreModule } from './+state/notification';
import { NotificationUiComponent } from './component/notification-ui.component';
import { MatIconModule } from '@angular/material/icon';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorOutsideNgrx } from './interceptors/interceptor-outside-ngrx.service';
import { NgxJsonViewerModule } from '@angular-monorepo/shared/ui-components';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, NotificationStoreModule, MatIconModule, NgxJsonViewerModule, MatButtonModule],
  declarations: [NotificationUiComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorOutsideNgrx,
      multi: true,
    },
  ],
})
export class NotificationsDomainModule {}
