import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ENVIRONMENT_CONFIG, EnvironmentConfig } from '@angular-monorepo/shared/util-utils';
import { Credentials, Tokens } from '../entities/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseUrl = `${this.environment.api.scheme}://${this.environment.api.baseDomain}${this.environment.api.suffixDomain}${this.environment.api.prefix}`;

  constructor(private http: HttpClient, @Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfig) {}

  login(credentials: Credentials): Observable<Tokens> {
    return this.http.post<Tokens>(`${this.baseUrl}/token/`, credentials);
  }

  refresh(tokens: Tokens): Observable<Tokens> {
    return this.http.post<Tokens>(`${this.baseUrl}/token/refresh/`, tokens);
  }

  logout(tokens: Tokens): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/token/blacklist/`, tokens);
  }

  getTokensFromCache(): Tokens {
    return {
      access: localStorage.getItem(this.environment.localStorageKeys.accessToken) || undefined,
      refresh: localStorage.getItem(this.environment.localStorageKeys.refreshToken) || undefined,
    };
  }

  areTokensFilled(tokens: Tokens): tokens is Tokens {
    return !!(tokens.access && tokens.refresh);
  }

  cleanStorage(): void {
    localStorage.removeItem(this.environment.localStorageKeys.accessToken);
    localStorage.removeItem(this.environment.localStorageKeys.refreshToken);
  }

  setTokens(tokens: Tokens): void {
    localStorage.setItem(this.environment.localStorageKeys.accessToken, tokens.access || '');
    localStorage.setItem(this.environment.localStorageKeys.refreshToken, tokens.refresh || '');
  }
  register(id: string, data: { email: string; password: string; token: string }) {
    return this.http.post(`${this.baseUrl}/partner/account/${id}/set_user/`, data);
  }
}
