<mat-accordion [multi]="field.props?.multiple ?? true">
<!--  [class.mat-elevation-warn-z6]="_cache[$any(f?.id)]"-->
  <mat-expansion-panel
    class="mat-elevation-z4"
    [class.mat-elevation-warn-z6]="checkError(f)"

    [disabled]="f.props?.disabled ?? false" [expanded]="f.props?.expanded ?? true"
                       *ngFor="let f of field.fieldGroup; let i = index; let last = last;">
    <mat-expansion-panel-header >
      <mat-panel-title *ngIf="f.props?.expansionLabel">
        <h2>{{f.props?.expansionLabel}}</h2>

      </mat-panel-title>
      <mat-panel-description>
        <span>{{f.props?.description}}</span>
        <mat-icon color="warn" *ngIf="checkError(f)">error</mat-icon>
<!--        <mat-icon color="warn" *ngIf="_cache[$any(f?.id)]">error</mat-icon>-->
      </mat-panel-description>
    </mat-expansion-panel-header>
    <formly-field [field]="f"></formly-field>
  </mat-expansion-panel>
</mat-accordion>
