import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ENVIRONMENT_CONFIG,
  EnvironmentConfig,
  GenericService,
} from '@angular-monorepo/shared/util-utils';
import { Attribute } from '../entities/attribute';

@Injectable({
  providedIn: 'root',
})
export class AttributesServive extends GenericService<Attribute> {
  protected constructor(
    protected http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) protected environment: EnvironmentConfig
  ) {
    super(http, environment);
    this.setUrl('/catalogue/attribute/');
  }
}
