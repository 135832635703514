import { saveAs } from 'file-saver-es';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';
import {ListParams, makeOptions} from "./generic-service";

@Injectable({ providedIn: 'root' })
export class FileDownloader {
  get(url: string, filename?: string, params = {} as ListParams) {
    return this.http
      .get(url, {
        params: params ? makeOptions(params) : {},
        responseType: 'blob',
        headers: {
          _internal_showError: 'true',
        },
      })
      .pipe(
        tap((response) => {
          saveAs(response, filename);
        })
      );
  }
  constructor(private http: HttpClient) {}
}
