import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { importationActions } from '../+state/importation';
import { Importation } from '../entities/importation';
import { Actions, ofType } from '@ngrx/effects';
import { ImportationServive } from '../infrastructure/importation.service';

@Injectable({ providedIn: 'root' })
export class ImportationDialogFacade {
  addSuccess$ = this.actions.pipe(ofType(importationActions.addSuccess));
  constructor(private store: Store, private actions: Actions, private importationServive: ImportationServive) {}
  submit(model: Omit<Importation, 'imported_file'> & { files: File[] }) {
    const file = model.files[0];
    const tmpModel = JSON.parse(JSON.stringify(model)) as Importation;
    const mimeToSpecName: any = {
      'text/csv': 'csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
      'application/json': 'json',
    };
    const integration_specification_name = [
      'dommarket',
      model.type === 'D' ? 'deletion' : '',
      mimeToSpecName[file.type] ?? '',
    ]
      .filter((v) => !!v)
      .join('-');

    // 'deletion' importation type doesn't exist anymore, it's contained in catalogue
    // but we still need to get the info in integration_specification_name
    if (tmpModel.type === 'D') {
      tmpModel.type = 'C';
    }

    tmpModel.imported_file = new File([file], file.name, { type: file.type });
    tmpModel.integration_specification_name = integration_specification_name;
    this.store.dispatch(importationActions.add({ entity: tmpModel }));
  }

  // downloadFile() {
  //   this.importationServive.exportFile();
  // }
}
