import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'components-account-base',
  templateUrl: './account-base.component.html',
  styleUrls: ['./account-base.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountBaseComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
