import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, debounceTime, EMPTY, Observable, startWith, throttleTime } from 'rxjs';
import { skipEmpty } from './rxjs-utils';
import { SerializableHttpErrorResponse } from './shared.models';
import { untilDestroyed } from '@ngneat/until-destroy';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { map } from 'rxjs/operators';

export function manageSearchQuery(form?: AbstractControl, paginator?: MatPaginator, sort?: MatSort) {
  return combineLatest([
    form ? form.valueChanges.pipe(startWith(form.value), debounceTime(300)) : EMPTY,
    paginator
      ? paginator.page.pipe(
          startWith({
            pageIndex: paginator.pageIndex,
            pageSize: paginator.pageSize,
          })
        )
      : EMPTY,
    sort ? sort.sortChange.pipe(startWith(undefined)) : EMPTY,
  ]).pipe(
    throttleTime(500, undefined, { trailing: true, leading: true }),
    map(([formValue, page, sort]) => {
      return {
        ...formValue,
        ...(page
          ? {
              offset: page.pageIndex * page.pageSize,
              limit: page.pageSize,
            }
          : {}),
      };
    })
  );
}

export const showErrorIfValue = (field: FieldType) => {
  // if the field is untouched BUT it has a value, we want to show the error.
  // This can happen if the validation change days after the data is already saved
  return (
    !!field.formControl?.invalid &&
    (field.formControl?.touched ||
      !!field?.formControl?.value ||
      field.options.parentForm?.submitted ||
      !!field.field.validation?.show)
  );
};

// this is the default formly showError
// default showError can be found here : https://github.com/ngx-formly/ngx-formly/blob/main/src/core/src/lib/services/formly.config.ts
export const defaultShowError = (field: FieldType) => {
  return (
    field.formControl?.invalid &&
    (field.formControl?.touched || field.options.parentForm?.submitted || !!field.field.validation?.show)
  );
};

export interface AutofillFormErrors {
  form: UntypedFormGroup;
  errors$: Observable<SerializableHttpErrorResponse | null>;
}

function addError(control: AbstractControl, key: string, value: unknown): void {
  if (control.errors) {
    control.errors[key] = value;
  } else {
    control.setErrors({ [key]: value }, { emitEvent: true });
  }
}

// export function setServerErrors(
//   control: FormGroup,
//   err: SerializableHttpErrorResponse
// ): void {
//   if (typeof err.error === 'string') {
//     addError(control, 'server', [err.error]);
//     return;
//   }
//   for (const fieldName in err.error) {
//     const error = err.error[fieldName];
//
//     if (control.contains(fieldName)) {
//       const field = control.get(fieldName);
//
//       field?.setErrors({ server: error }, { emitEvent: true });
//     } else {
//       addError(control, fieldName, error);
//     }
//   }
// }

export function setServerErrors(control: AbstractControl, error: { [name: string]: string } | string): void {
  if (typeof error === 'string') {
    control.setErrors({ server: error });
    return;
  }

  for (const fieldName in error) {
    const childError = error[fieldName];
    if (control instanceof UntypedFormGroup && control.contains(fieldName)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const childField = control.get(fieldName)!;
      setServerErrors(childField, childError);
    } else {
      control.setErrors({ server: error });
    }
  }
}

export function HandleFormErrors(component: AutofillFormErrors) {
  return component.errors$.pipe(skipEmpty(), untilDestroyed(component)).subscribe((errors) => {
    setServerErrors(component.form, errors.error);
  });
}

export function BuildForm(config: FormlyFieldConfig[]): FormlyFieldConfig[] {
  const globalErrorKeys = ['detail', 'non_field_errors'];

  return [
    ...config,
    ...globalErrorKeys.map((key: string) => ({
      key,
      type: 'globalErrors',
      className: 'sticky-field',
    })),
  ];
}

export const phoneValidator = {
  expression: (c: UntypedFormControl) => /^(([\d]*)|( *[\d]+[\d ]*))$/.test(c.value),
  message: (error: never, field: FormlyFieldConfig) => `"${field?.formControl?.value}" is not a valid phone number`,
};

export const emailValidator = {
  expression: (c: UntypedFormControl) => Validators.email(c) == null,
  message: (error: never, field: FormlyFieldConfig) => `"${field?.formControl?.value}" is not a valid email`,
};

export const UrlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;

export function minlengthValidationMessage(err: unknown, field: FormlyFieldConfig) {
  return `Should have atleast ${field?.props?.minLength} characters`;
}

export function maxlengthValidationMessage(err: unknown, field: FormlyFieldConfig) {
  return `This value should be less than ${field?.props?.maxLength} characters`;
}

export function minValidationMessage(err: unknown, field: FormlyFieldConfig) {
  return `This value should be more than ${field?.props?.min}`;
}

export function maxValidationMessage(err: unknown, field: FormlyFieldConfig) {
  return `This value should be less than ${field?.props?.max}`;
}
