import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { productActions, productSelectors } from '../+state/product';
import { Product } from '../entities';
import { Actions, concatLatestFrom, ofType } from '@ngrx/effects';
import { ProductServive } from '../infrastructure/product.service';
import { userSelectors } from '@angular-monorepo/authentication/domain';
import { concatMap, filter, map, switchMap } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { ListParams } from '@angular-monorepo/shared/util-utils';

@Injectable({
  providedIn: 'root',
})
export class ProductFacade {
  products$ = this.store.select(productSelectors.selectProductAll);
  paginator$ = this.store.select(productSelectors.selectPagination);
  routeProductId$ = this.store.select(productSelectors.selectRouteProductId);
  routeProduct$ = this.store.select(productSelectors.selectRouteProduct);
  productUpdated$ = this.actions.pipe(ofType(productActions.updateSuccess));
  // currentProduct$ = this.store.select(productSelectors.);
  productDeleted$ = this.actions.pipe(ofType(productActions.deleteSuccess));
  constructor(private store: Store, private actions: Actions, public productService: ProductServive) {
    this.routeProductId$.subscribe((productId) => {
      if (!isNaN(Number(productId))) {
        this.get(Number(productId));
      }
    });
  }

  updateStore(product: any) {
    this.store.dispatch(productActions.updateSuccessFinal({ entity: product }));
  }

  loadNoStore(params: any = {}) {
    return this.store.select(userSelectors.selectUser).pipe(
      filter((user) => !!user?.partner_id),
      // eslint-disable-next-line ngrx/avoid-mapping-selectors
      switchMap((user) => {
        params = {
          ...params,
          ...(user?.partner_id ? { children__stockrecords__partner: user.partner_id } : {}),
        };
        return this.productService.list(params);
      })
    );
  }

  get(productId: number) {
    this.store.dispatch(productActions.get({ id: productId }));
  }

  loadPaginate(params?: ListParams) {
    this.store.dispatch(productActions.loadWithPagination({ params }));
  }
  delete(productId: number) {
    this.store.dispatch(productActions.delete({ id: productId }));
  }

  update(entity: Product) {
    this.store.dispatch(productActions.update({ entity }));
  }
}
