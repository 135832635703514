<div style="margin-bottom: 1em">
  <label [for]="'filedrop_'+id">
    <div class="file-deposit" dropFile (fileDropped)="onFileDropped($event)" [multiple]="props.multiple">
      <input
        #accessor="FileValueAccessor"
        [multiple]="props.multiple"
        [formControl]="$any(formControl)"
        [formlyAttributes]="field"
        type="file"
        [id]="'filedrop_'+id"
        (change)="fileBrowseHandler($any($event.target).files)"
        (ngModelChange)="addExistingFiles($event)"
      />
      <mat-icon>upload_file</mat-icon>
      <h2>{{props.dropText ?? 'Click to upload or drag and drop desired files.'}}</h2>

    </div>
  </label>

  <div class="file-list">
    <div *ngFor="let file of accessor.files; let i = index" class="file-entry flex items-baseline">
      <span>{{file.name}} <small>({{file.size | filesize}})</small></span>
      <div class="flex-1"></div>
      <button mat-icon-button (click)="deleteImage(i)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <components-formly-field-error [field]="field"></components-formly-field-error>

</div>

