<ng-container *ngIf="{
  user: user$|async,
  isLogged: isLogged$|async
} as state">
  <div class="app">
    <mat-toolbar *ngIf='state.isLogged && state.user' class='p-0 shadow sticky top-0 z-50 toolbar-theme' role="heading">
      <a class='h-full w-auto' routerLink="/">
        <img
          alt="Dommarket"
          class="h-5/6 w-full"
          src="assets/dommarket-logo.svg">
      </a>
      <span class="flex-1"></span>
      <span>{{appVersion}}</span>
      <span *ngIf="state.user">{{ state.user.email }}</span>
      <button mat-icon-button>
        <mat-icon>notifications</mat-icon>
      </button>
    </mat-toolbar>


    <ngx-loading-bar [fixed]="false" height="3px" [style.z-index]="100"></ngx-loading-bar>

    <div class="main-content">
      <mat-card *ngIf="state.isLogged" class="nav-list-wrapper">
        <mat-nav-list class="nav-list">
          <ng-container *ngFor="let item of items">
            <ng-container *ngIf="item; else noItem">
              <mat-list-item (click)="item.action ? item.action() : ''" *ngIf="!item.link; else link">
                <mat-icon class="m-3">{{item.icon}}</mat-icon>
                <span>{{item.name}}</span>
              </mat-list-item>
              <ng-template #link>
                <a [routerLink]="item.link" mat-list-item routerLinkActive="list-item-active">
                  <mat-icon class="m-3">{{item.icon}}</mat-icon>
                  <span>{{item.name}}</span>
                </a>
              </ng-template>
            </ng-container>
            <ng-template #noItem>
              <mat-divider></mat-divider>
            </ng-template>
          </ng-container>
        </mat-nav-list>
      </mat-card>
      <div cdkScrollable class="flex-1 overflow-auto p-[50px]">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-container>
<simple-notifications></simple-notifications>

<ng-template #contentTemplate let-json_error="json_error">
  <div *ngIf="json_error" class="p-0.5 error-json-wrapper border-2 border-dashed border-[rgba(0,0,0,0.5)]" (click)="$event.stopPropagation()">
    <ngx-json-viewer [json]="json_error"></ngx-json-viewer>
  </div>


</ng-template>
