import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, of, tap } from 'rxjs';
import { Category } from '../entities/category';
import { categoryActions, categorySelectors } from '../+state/category';
import { asNumber, ListParams, routerSelectors } from '@angular-monorepo/shared/util-utils';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CategoryFacade {
  categories$ = this.store.select(categorySelectors.selectCategoryAll);
  selectCategoryNodeById$ = this.store.select(categorySelectors.selectCategoryNodeById);
  categoryEntities$ = this.store.select(categorySelectors.selectCategoryNodeById);
  treeCategoryNodeRoot$ = this.store.select(categorySelectors.selectTreeCategoryNode);
  selectedCategoryId$ = this.store.select(categorySelectors.selectRouteCategoryId);
  loading$ = this.store.select(categorySelectors.selectCategoryLoading);
  categoryParentId$ = this.store.select(routerSelectors.selectQueryParam('parent')).pipe(
    filter((id) => {
      return !isNaN(Number(id));
    }),
    // eslint-disable-next-line ngrx/avoid-mapping-selectors
    map((id) => Number(id))
  );
  categoryId$ = this.selectedCategoryId$.pipe(
    switchMap((id) => {
      if (id === 'new') {
        return this.categoryParentId$;
      }
      return of(id).pipe(asNumber());
    })
  );
  categoryLoader$ = this.categoryId$.pipe(
    tap((id) => {
      this.store.dispatch(categoryActions.get({ id }));
    })
  );

  categoryAttributes$ = this.selectedCategoryId$.pipe(
    asNumber(),
    switchMap((id) => this.store.select(categorySelectors.selectCategoryAttributes(id)))
  );

  category$ = this.categoryLoader$.pipe(
    switchMap((id) => {
      return this.store.select(categorySelectors.selectCategoryById(id));
    }),
    shareReplay()
  );

  constructor(private store: Store) {}

  delete(category: Category) {
    this.store.dispatch(categoryActions.delete({ entity: category }));
  }

  loadAll(params?: ListParams) {
    this.store.dispatch(categoryActions.load({ params: params }));
  }

  updateOrCreate(entity: Category) {
    if (entity.id) {
      this.store.dispatch(categoryActions.update({ entity }));
    } else {
      this.categoryParentId$.pipe(first()).subscribe((categoryParentId) => {
        this.store.dispatch(
          categoryActions.add({
            entity: {
              ...entity,
              parent_category: categoryParentId,
            },
          })
        );
      });
    }
  }
}
