import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, productVariantFeatureKey, ProductVariantState } from './product-variant.reducer';

const { selectIds, selectAll, selectEntities, selectTotal } = adapter.getSelectors();

const selectFeatureSelector = createFeatureSelector<ProductVariantState>(productVariantFeatureKey);

const selectState = createSelector(selectFeatureSelector, (state) => state);

const selectProductVariantIds = createSelector(selectState, selectIds);
const selectProductVariantAll = createSelector(selectState, selectAll);
const selectProductVariantTotal = createSelector(selectState, selectTotal);

const selectProductVariantEntities = createSelector(selectState, selectEntities);
const selectProductVariantLoading = createSelector(selectState, (state) => state.loading);

const selectPagination = createSelector(selectFeatureSelector, (state) => ({
  next: state.next,
  previous: state.previous,
  count: state.count,
}));
const selectSelectedEntityId = createSelector(selectFeatureSelector, (state) => state.selectedEntityId);

const selectSelectedEntity = createSelector(
  selectProductVariantEntities,
  selectSelectedEntityId,
  (entities, selectedEntityId) => {
    if (selectedEntityId) {
      return entities[selectedEntityId];
    } else {
      return undefined;
    }
  }
);

export const productVariantSelectors = {
  selectProductVariantIds,
  selectProductVariantAll,
  selectProductVariantTotal,
  selectProductVariantEntities,
  selectProductVariantLoading,
  selectPagination,
  selectSelectedEntityId,
  selectSelectedEntity,
};
