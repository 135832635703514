import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@angular-monorepo/authentication/domain';
import { AnalyticsFeatureKpiModule } from '@angular-monorepo/analytics/feature-kpi';
import { BatchFilesFeatureImportationModule } from '@angular-monorepo/batch-files/feature-importation';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('@angular-monorepo/account/feature-edit-account').then((m) => m.AccountFeatureEditAccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('@angular-monorepo/authentication/feature-authentication').then(
        (m) => m.AuthenticationFeatureAuthenticationModule
      ),
  },
  {
    path: 'attributes',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@angular-monorepo/attributes/feature-attributes').then((m) => m.AttributesFeatureAttributesModule),
  },
  {
    path: 'categories',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@angular-monorepo/category/feature-category').then((m) => m.CategoryFeatureCategoryModule),
  },
  {
    path: 'products',
    loadChildren: () => import('@angular-monorepo/product/feature-product').then((m) => m.ProductFeatureProductModule),
  },
  {
    path: 'batch_files',
    loadChildren: () =>
      import('@angular-monorepo/batch-files/feature-importation').then((m) => m.BatchFilesFeatureImportationModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('@angular-monorepo/order/feature-order').then((m) => m.OrderFeatureOrderModule),
  },
  {
    path: 'analytics',
    loadChildren: () => import('@angular-monorepo/analytics/feature-kpi').then((m) => m.AnalyticsFeatureKpiModule),
  },
  {
    path: '**',
    redirectTo: 'account',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'corrected',
      // used in combination with runGuardsAndResolvers: "always" on component route to enable guards reload on logout
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
