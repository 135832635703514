import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { EntityState } from '@ngrx/entity/src/models';
import { Action, createReducer, on } from '@ngrx/store';
import { importationActions } from './importation.actions';
import { Importation } from '../../entities/importation';
import { SerializableHttpErrorResponse, StatePaginate } from '@angular-monorepo/shared/util-utils';

export const importationFeatureKey = 'importation';

export const adapter: EntityAdapter<Importation> = createEntityAdapter<Importation>();

export interface ImportationState extends EntityState<Importation>, StatePaginate {
  errors?: SerializableHttpErrorResponse;
}

const initialState: ImportationState = adapter.getInitialState({
  loading: false,
  count: 0,
});
const { selectAll } = adapter.getSelectors();
const entitiesReducer = createReducer(
  initialState,
  on(
    importationActions.load,
    importationActions.loadWithPagination,
    importationActions.add,
    importationActions.get,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  // error action
  on(
    importationActions.getFailure,
    importationActions.loadFailure,
    importationActions.loadWithPaginationFailure,
    importationActions.addFailure,
    (state, { error }) => ({
      ...state,
      errors: error,
      loading: false,
    })
  ),

  on(importationActions.loadSuccess, (state, { entities }) => {
    return adapter.setAll(entities, {
      ...state,
      loading: false,
    });
  }),
  on(importationActions.loadWithPaginationSuccess, (state, { paginateEntities }) => {
    return adapter.setAll(paginateEntities.results, {
      ...state,
      count: paginateEntities.count,
      next: paginateEntities.next,
      previous: paginateEntities.previous,
      loading: false,
    });
  }),
  on(importationActions.addSuccess, (state, { entity }) => {
    return adapter.setAll([entity, ...selectAll(state)], { ...state, count: (state.count ?? 0) + 1, loading: false });
  }),
  on(importationActions.getSuccess, (state, { entity }) => {
    return adapter.upsertOne(entity, {
      ...state,
      loading: false,
    });
  })
);

export function importationReducer(state: ImportationState = initialState, action: Action): ImportationState {
  return entitiesReducer(state, action);
}
