import { NgModule } from '@angular/core';
import { FormlyFieldErrorComponent } from './formly-field-error.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormlyModule } from '@ngx-formly/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [FormlyFieldErrorComponent],
  imports: [CommonModule, MatFormFieldModule, FormlyModule],
  exports: [FormlyFieldErrorComponent],
})
export class FormlyFieldErrorModule {}
