import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { partnerFeatureKey, partnerReducer } from './partner.reducer';
import { PartnerStoreEffects } from './partner.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(partnerFeatureKey, partnerReducer),
    EffectsModule.forFeature([PartnerStoreEffects]),
  ],
})
export class PartnerStoreModule {}
