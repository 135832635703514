import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviousButtonComponent } from './previous-button.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [PreviousButtonComponent],
  imports: [CommonModule, MatButtonModule, RouterModule, MatIconModule],
  exports: [PreviousButtonComponent],
})
export class PreviousButtonModule {}
