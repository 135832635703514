import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { AuthActions, AuthenticationFacade } from '@angular-monorepo/authentication/domain';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { defaultShowError } from '@angular-monorepo/shared/util-utils';

@UntilDestroy()
@Component({
  selector: 'authentication-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {
  private token = '';
  private id = '';
  form = new UntypedFormGroup({});
  options = {
    showError: defaultShowError,
  } as FormlyFormOptions;
  model = { email: '', password1: '', password2: '' };
  fields: FormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      className: 'py-10',
      props: {
        label: 'Email address',
        placeholder: 'Enter email',
        required: true,
      },
    },
    {
      key: 'password1',
      type: 'input',
      props: {
        label: 'Password',
        required: true,
        type: 'password',
      },
    },
    {
      key: 'password2',
      type: 'input',
      props: {
        label: 'Password',
        required: true,
        type: 'password',
      },
    },
  ];

  constructor(
    private authenticationFacade: AuthenticationFacade,
    private route: ActivatedRoute,
    private router: Router,
    private action: Actions
  ) {}

  ngOnInit(): void {
    this.action.pipe(untilDestroyed(this), ofType(AuthActions.registerSuccess)).subscribe(() => {
      this.router.navigate(['/']);
    });
    this.route.params.subscribe((params) => {
      this.token = params['token'];
      this.id = params['id'];
    });
  }

  onSubmit(model: { password2: string; password1: string; email: string }) {
    this.authenticationFacade.register(this.id, {
      email: model.email,
      password: model.password1,
      token: this.token,
    });
  }
}
