<ng-container *ngIf="{
  loading: isLoading$ | async
} as state">
  <components-account-base>
    <h1 class='text-color mat-primary pb-10'>Welcome back! Please login to your account.</h1>
    <form [formGroup]='form' (ngSubmit)='onSubmit(model)'>
      <formly-form [form]='form' [fields]='fields' [model]='model' [options]="options"></formly-form>
      <div class="flex flex-col justify-start">
        <a class='mb-12 self-end' [href]='forgotPasswordUrl'>Forgot Password ?</a>
        <button mat-raised-button type='submit' class='self-center button-container' color='primary'
                [disabled]="state.loading">
          <span>Sign In</span>
          <ng-container *ngIf="state.loading">
            <div class="spinner-container">
              <mat-spinner diameter="24"></mat-spinner>
            </div>
          </ng-container>
        </button>
      </div>
    </form>
  </components-account-base>
</ng-container>
