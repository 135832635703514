import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthenticationFacade } from '@angular-monorepo/authentication/domain';
import { NotificationUiFacade } from '@angular-monorepo/notifications/domain';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Store } from '@ngrx/store';
import { environment } from '../environments/environment';

@Component({
  selector: 'angular-monorepo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('contentTemplate') contentTemplate!: TemplateRef<never>;
  appVersion = environment.appVersion;
  isLogged$ = this.authenticationFacade.isLogged$;
  user$ = this.authenticationFacade.user$;

  items = [
    // {
    //   icon: 'visibility',
    //   name: 'Overview',
    //   link: ['/home'],
    // },
    {
      icon: 'edit_attributes',
      name: 'Attributes',
      link: ['/attributes'],
    },
    {
      icon: 'category',
      name: 'Categories',
      link: ['/categories'],
    },
    {
      icon: 'grid_view',
      name: 'Products',
      link: ['/products'],
    },
    {
      icon: 'upload',
      name: 'Batch files',
      link: ['/batch_files'],
    },
    {
      icon: 'clear_all',
      name: 'Orders',
      link: ['/orders'],
    },
    {
      icon: 'bar_chart',
      name: 'Analytics',
      link: ['/analytics'],
    },
    null,
    {
      icon: 'account_circle',
      name: 'Account',
      link: ['/account'],
    },
    {
      icon: 'exit_to_app',
      name: 'Sign out',
      action: () => this.logout(),
    },
  ];

  constructor(
    private store: Store,
    private authenticationFacade: AuthenticationFacade,
    private notificationFacade: NotificationUiFacade,
    private overlayContainer: OverlayContainer
  ) {}

  logout() {
    this.authenticationFacade.logout();
  }

  ngOnInit(): void {
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    overlayContainerClasses.add('light-theme');
  }

  ngAfterViewInit(): void {
    this.notificationFacade.setTemplate(this.contentTemplate);
  }
}

class AppComponentImpl extends AppComponent {}
