import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, distinctUntilChanged, skip, startWith, Subscription, tap, withLatestFrom } from 'rxjs';
import { NestedTreeControl } from '@angular/cdk/tree';
import { CategoryFacade, CategoryNode } from '@angular-monorepo/category/domain';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { slideVerticalAnimation } from '../../../animations';
import { skipEmpty } from '@angular-monorepo/shared/util-utils';
import { Attribute } from '@angular-monorepo/attributes/domain';
import { Dictionary } from '@ngrx/entity';

@UntilDestroy()
@Component({
  selector: 'components-formly-product-category-attributes-selection',
  templateUrl: './formly-product-category-selection.component.html',
  styleUrls: ['./formly-product-category-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideVerticalAnimation],
})
export class FormlyProductCategorySelectionComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  @ViewChild('dialog') tpl!: TemplateRef<any>;
  categoriesNodesById$ = this.categoryFacade.selectCategoryNodeById$;
  checklistSelection = new SelectionModel<number>(true /* multiple */, []);
  categoriesNode$ = this.categoryFacade.treeCategoryNodeRoot$.pipe(
    tap((data) => {
      this.treeControl.dataNodes = data;
      this.treeControl.expandAll();
    })
  );
  treeControl = new NestedTreeControl<CategoryNode>((node) => node?.children);
  selectionSubscription = new Subscription();

  hasChild = (_: number, node: CategoryNode) => (node?.children?.length ?? 0) > 0;

  constructor(private categoryFacade: CategoryFacade, public dialog: MatDialog, private cd: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit() {
    this.checklistSelection.clear();
    combineLatest([
      this.formControl.valueChanges.pipe(startWith(this.formControl.value)),
      this.categoriesNodesById$.pipe(skipEmpty()),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([values, categoryEntities]) => {
        this.selectionSubscription.unsubscribe();

        const selection = new SelectionModel<number>(true, this.checklistSelection.selected);
        for (const category of values as number[]) {
          selection.select(category);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.propagateSelection(categoryEntities[category]!, selection);
        }

        this.checklistSelection.clear();
        this.checklistSelection.select(...selection.selected);
        // console.log('after checklist', this.checklistSelection.selected);
        // this.cd.detectChanges();

        this.selectionSubscription = this.subscribeSelectionChange();
      });
  }

  openDialog(tpl: TemplateRef<any>) {
    this.dialog.open(tpl, {});
  }
  getParent = (node: CategoryNode) => node?.parent;

  isExpandable = (node: CategoryNode) => this.hasChild(0, node);

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: CategoryNode, selection = this.checklistSelection): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return selection.isSelected(child.category?.id);
      });
    return descAllSelected;
  }
  // /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: CategoryNode, selection = this.checklistSelection): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => selection.isSelected(child.category?.id));
    return result && !this.descendantsAllSelected(node);
  }

  propagateSelection(node: CategoryNode, selection: SelectionModel<number>) {
    //propatage down
    const descendants = this.treeControl.getDescendants(node);
    selection.isSelected(node?.category?.id)
      ? selection.select(...descendants.map((v) => v.category?.id))
      : selection.deselect(...descendants.map((v) => v.category.id));
    // this.checkAllParentsSelection(node);

    // //propatage up
    let cNode = this.getParent(node);
    while (cNode) {
      const allChildrenSelected = this.descendantsAllSelected(cNode, selection);
      if (allChildrenSelected) {
        selection.select(cNode.category?.id);
      } else {
        selection.deselect(cNode.category?.id);
      }
      cNode = this.getParent(cNode);
    }
  }
  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  toggleSelectionUI(node: CategoryNode): void {
    // console.log('before checklist ', this.checklistSelection.selected);
    const selection = new SelectionModel<number>(true, this.checklistSelection.selected);
    selection.toggle(node.category?.id);
    this.propagateSelection(node, selection);

    this.checklistSelection.clear();
    this.checklistSelection.select(...selection.selected);

    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
  }

  viewCategoryAttributes(category: string) {
    const ref = this.dialog.open(this.tpl, {
      data: { category, attributes: this.options?.formState?.parent_attributes },
    });
  }

  attributeAdded($event: Attribute) {
    this.props.attributeAdded([$event]);
  }

  private subscribeSelectionChange() {
    return this.checklistSelection.changed.pipe(untilDestroyed(this)).subscribe(() => {
      this.formControl.setValue(this.checklistSelection.selected);
    });
  }
}
