import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'components-readonly-input',
  templateUrl: './readonly-input.component.html',
  styleUrls: ['./readonly-input.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadonlyInputComponent implements OnInit {
  @Input() data: string | undefined | null = '';
  @Input() label: string | undefined | null = '';
  @Input() suffix = '';
  form = new UntypedFormControl();

  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  imports: [CommonModule],
  declarations: [ReadonlyInputComponent],
  exports: [ReadonlyInputComponent],
})
export class ReadOnlyInputModule {}
