import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { AppGraphicsModule } from './graphic-module/app-graphics.module';
import {
  FormlyAccordionGroupComponent,
  FormlyArrayFieldComponent,
  FormlyCheckboxNullableComponent,
  FormlyFieldErrorModule,
  FormlyPrefixSuffixWrapperComponent,
  FormlyProductPackagesFieldComponent,
  FormlyRichTextEditorComponent,
  FormlyWrapperCardComponent,
} from './formly';
import { AccountBaseComponent } from './account-base/account-base.component';
import { QuillModule } from 'ngx-quill';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedModule } from '@angular-monorepo/shared/util-utils';
import { ReadOnlyInputModule } from './readonly-input/readonly-input.component';

const imports_exports_modules = [AppGraphicsModule, ReadOnlyInputModule] as never[];
const declarables = [
  FormlyArrayFieldComponent,
  FormlyRichTextEditorComponent,
  AccountBaseComponent,
  FormlyPrefixSuffixWrapperComponent,
  FormlyProductPackagesFieldComponent,
  FormlyCheckboxNullableComponent,
] as never[];

@NgModule({
  imports: [
    ...imports_exports_modules,
    // SharedFeatureHighlightTextModule,
    CommonModule,
    SharedModule,
    AppGraphicsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule,
    QuillModule,
    FormsModule,
    MatExpansionModule,
    RouterModule,
    MatTreeModule,
    MatCheckboxModule,
    FormlyFieldErrorModule,
  ],
  declarations: [...declarables, FormlyWrapperCardComponent, FormlyAccordionGroupComponent],
  exports: [
    CommonModule,
    ...imports_exports_modules,
    ...declarables,
    FormlyModule,
    FormlyMaterialModule,
    ReactiveFormsModule,
  ],
})
export class SharedUiComponentsModule {}
