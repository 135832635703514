import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { Tokens, User } from '../../entities/user';
import { SerializableHttpErrorResponse } from '@angular-monorepo/shared/util-utils';

export const authFeatureKey = 'auth';

export interface AuthState {
  isLoading: boolean;
  loginError: SerializableHttpErrorResponse | null;
  tokens: Tokens | null;
  refreshExpirationDate: string | null; // date stringified
  isLogged: boolean;
  user: User | null;
  redirectUrl: string | null;
}

export const authState: AuthState = {
  isLoading: false,
  tokens: null,
  refreshExpirationDate: null,
  isLogged: false,
  user: null,
  redirectUrl: null,
  loginError: null,
};

export const reducer = createReducer(
  authState,

  on(AuthActions.login, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AuthActions.loginSuccess, (state, action) => ({
    user: null,
    refreshExpirationDate: null,
    tokens: action.tokens,
    isLogged: true,
    loginError: null,
    isLoading: false,
    redirectUrl: null,
  })),
  on(AuthActions.loginFailure, (state, action) => ({
    ...state,
    loginError: action.error,
    isLoading: false,
  })),
  on(AuthActions.resetLoginError, (state) => ({
    ...state,
    loginError: null,
  })),
  on(
    AuthActions.logoutSuccess,
    AuthActions.refreshFailure,
    AuthActions.autoLogout,
    (state) => ({
      ...state,
      tokens: null,
      isLogged: false,
      user: null,
    })
  ),
  on(AuthActions.refreshSuccess, (state, action) => ({
    ...state,
    tokens: action.tokens,
  })),
  on(AuthActions.setNewRefreshDate, (state, action) => ({
    ...state,
    refreshExpirationDate: action.refreshExpirationDate,
  })),
  on(AuthActions.setUser, (state, action) => ({
    ...state,
    user: action.user,
  })),
  on(AuthActions.setRedirectUrl, (state, action) => ({
    ...state,
    redirectUrl: action.redirectUrl,
  }))
);
