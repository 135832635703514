import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { SerializableHttpErrorResponse } from '@angular-monorepo/shared/util-utils';
import { NotificationUiFacade } from '@angular-monorepo/notifications/domain';
import { exportationActions } from './exportation.actions';
import { ExportationService } from '../../infrastructure/exportation.service';

@Injectable()
export class ExportationStoreEffects {
  loadWithPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(exportationActions.loadWithPagination),
      exhaustMap((action) =>
        this.entityService.listWithPagination(action.params).pipe(
          map((paginateEntities) => exportationActions.loadWithPaginationSuccess({ paginateEntities })),
          catchError((error: SerializableHttpErrorResponse) =>
            of(exportationActions.loadWithPaginationFailure({ error }))
          )
        )
      )
    );
  });

  add$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(exportationActions.add),
      mergeMap((action) =>
        this.entityService.create({}).pipe(
          map((entity) => exportationActions.addSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(exportationActions.addFailure({ error })))
        )
      )
    );
  });
  constructor(
    protected actions$: Actions,
    protected entityService: ExportationService,
    protected store: Store,
    private notification: NotificationUiFacade
  ) {}
}
