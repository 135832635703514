import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldArrayType, FieldArrayTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  templateUrl: './formly-product-packages-field.component.html',
  styleUrls: ['./formly-product-packages-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyProductPackagesFieldComponent extends FieldArrayType implements OnInit {
  defaultOptions = {
    fieldArray: {
      fieldGroupClassName: 'group-field flex flex-row flex-wrap',
      fieldGroup: [
        {
          key: 'label',
          type: 'input',
          className: 'flex-1',
          props: {
            label: 'Label',
            icon: 'label',
          },
        },
        {
          key: 'height',
          type: 'input',
          className: 'flex-1',
          props: {
            label: 'height',
            type: 'number',
            step: 0.5,
            units: 'cm',
            required: true,
          },
        },
        {
          key: 'width',
          type: 'input',
          className: 'flex-1',
          props: {
            label: 'width',
            type: 'number',
            step: 0.5,
            units: 'cm',
            lang: 'en',
            required: true,
          },
        },
        {
          key: 'length',
          type: 'input',
          className: 'flex-1',
          props: {
            label: 'length',
            type: 'number',
            step: 0.5,
            units: 'cm',
            required: true,
          },
        },
        {
          key: 'weight',
          type: 'input',
          className: 'flex-1',
          props: {
            label: 'weight',
            type: 'number',
            step: 0.5,
            units: 'kg',
            required: true,
          },
        },
      ] as FormlyFieldConfig[],
    },
  } as FieldArrayTypeConfig;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }
  ngOnInit(): void {}

  add(i?: number, initialModel?: any, options?: { markAsDirty: boolean }) {
    super.add(i, initialModel, options);
    setTimeout(() => {
      this.formControl.markAsTouched();
      this.formControl.markAsDirty();
      this.cd.detectChanges();
    });
  }

  remove(i: number, options?: { markAsDirty: boolean }) {
    this.formControl.markAsTouched();
    super.remove(i, { markAsDirty: true });
    this.cd.detectChanges();
  }
}
