<h2 *ngIf="props.title" class="m-0 p-0">{{props.title}}</h2>
<quill-editor
  [formControl]="$any(formControl)"
  [format]="'html'"
  [placeholder]="props.placeholder || 'insert text here'"
  [theme]="props.theme || ''"
  [modules]="{
      toolbar: [
          ['bold', 'italic', 'strike'],
          [{ 'list': 'bullet' }],
          ['link']
      ]
  }">
</quill-editor>
