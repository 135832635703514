import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-array',
  templateUrl: './formly-array-field.component.html',
  styleUrls: ['./formly-array-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyArrayFieldComponent extends FieldArrayType {
  @ViewChild('addButton', { static: true, read: ElementRef })
  addButton!: ElementRef;

  remove(i: number) {
    super.remove(i, { markAsDirty: true });
  }

  add(i?: number, initialModel?: any) {
    super.add(i, initialModel, { markAsDirty: true });
    // wait for the element to be created
    setTimeout(() => {
      this.addButton.nativeElement.scrollIntoView({
        inline: 'end',
        behavior: 'smooth',
        block: 'end',
      });
    });
  }
}
