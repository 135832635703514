import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ENVIRONMENT_CONFIG,
  EnvironmentConfig,
  GenericService,
} from '@angular-monorepo/shared/util-utils';
import { User } from '../entities/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserServive extends GenericService<User> {
  protected constructor(
    protected http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) protected environment: EnvironmentConfig
  ) {
    super(http, environment);
    this.setUrl('/user/');
  }
  changePassword(
    id: number | undefined,
    data: {
      new_password: string;
      current_password: string;
    }
  ): Observable<User> {
    return this.http.post<User>(`${this.url}${id}/change_password/`, data);
  }
}
