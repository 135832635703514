import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';

export const formlyMaterialExtension: FormlyExtension = {
  prePopulate(field: FormlyFieldConfig) {
    if (
      field.type === 'input' ||
      field.type === 'select' ||
      field.type === 'textarea' ||
      field.type === 'autosize-textarea' ||
      field.type === 'address' ||
      field.type === 'autocomplete' ||
      field.type === 'group-select-autocomplete' ||
      field.type === 'radio' ||
      field.type === 'checkbox' ||
      field.type === 'checkbox-nullable' ||
      field.type === 'file-select-naive' ||
      field.type === 'autosize-textarea' ||
      field.type === 'toggle'
    ) {
      if (!field.wrappers?.includes('form-field')) {
        field.wrappers = [...(field.wrappers || []), 'form-field'];
      }

      field.props = {
        ...field?.props,
        appearance: field?.props?.appearance ?? 'fill',
      };

      if (field.type === 'toggle') {
        const classes = field?.className?.split(' ') ?? [];
        classes.push('formly-toggle');
        field.className = classes.join(' ');
        field.props = {
          ...field?.props,
          color: field?.props?.color ?? 'primary',
        };
      }
    }
  },
};
