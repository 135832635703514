import { NgModule } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { of } from 'rxjs';
import { Dictionary } from '@ngrx/entity';

@NgModule({
  imports: [
    FormlyModule.forChild({
      types: [
        {
          name: 'country',
          extends: 'autocomplete',
          defaultOptions: {
            props: {
              displayFn: (countryCode: string, countries: { [id: string]: string }) => {
                const tmp = countries?.[countryCode];
                if (tmp) {
                  return `${tmp} (${countryCode})`;
                }
                return '';
              },
              filter: (term: string, countries: { [id: string]: string }) => {
                return of(
                  Object.keys(countries)
                    ?.filter((country_key) => {
                      term = term?.toLowerCase() ?? '';
                      return (
                        country_key.toLowerCase().includes(term) ||
                        countries[country_key]?.toLowerCase()?.includes(term)
                      );
                    })
                    .map((countryCode) => {
                      let tmp = countries?.[countryCode];
                      if (tmp) {
                        tmp = `${tmp} <small>(${countryCode})</small>`;
                      } else {
                        tmp = '';
                      }
                      return {
                        value: countryCode,
                        label: tmp,
                      };
                    })
                  // countries
                  //   .filter((country: any) => country.printable_name.toLowerCase().includes(term.toLowerCase()))
                  //   .map((country: any) => ({
                  //     value: country.id,
                  //     label: country.printable_name,
                  //   }))
                );
              },
            },
          },
        },
      ],
    }),
  ],
})
export class FormlyCountryModule {}
