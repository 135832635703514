import { Importation } from '../../entities/importation';
import { createAction, props } from '@ngrx/store';
import {
  createFailureAction,
  createSuccessAction,
  ListParams,
  Paginate,
  SerializableHttpErrorResponse,
} from '@angular-monorepo/shared/util-utils';

export const importationActions = {
  load: createAction('[Importation/API] Load Importations', props<{ params?: ListParams }>()),
  loadSuccess: createAction('[Importation/API] Load Importations Success', props<{ entities: Importation[] }>()),
  loadFailure: createAction(
    '[Importation/API] Load Importations Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),

  loadWithPagination: createAction(
    '[Importation/API] Load with Pagination Importations',
    props<{ params?: ListParams }>()
  ),
  loadWithPaginationSuccess: createAction(
    '[Importation/API] Load with Pagination Importations Success',
    props<{ paginateEntities: Paginate<Importation[]> }>()
  ),
  loadWithPaginationFailure: createAction(
    '[Importation/API] Load with Pagination Importations Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),

  add: createAction('[Importation/API] Add Importation', props<{ entity: Importation }>()),
  addSuccess: createSuccessAction('[Importation/API] Add Importation Success', props<{ entity: Importation }>(), {
    title: 'File uploaded successfully',
  }),
  addFailure: createFailureAction(
    '[Importation/API] Add Importation Failure',
    props<{ error: SerializableHttpErrorResponse }>(),
    {
      title: 'Failed to upload file',
    }
  ),

  get: createAction('[Importation/API] Get Importation', props<{ id: number }>()),
  getSuccess: createAction('[Importation/API] Get Importation Success', props<{ entity: Importation }>()),
  getFailure: createAction(
    '[Importation/API] Get Importation Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),
  selectEntity: createAction('[Importation/API] Select Importation', props<{ id: string | number }>()),
};
