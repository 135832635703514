import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NotificationUiFacade {
  contentTemplate!: TemplateRef<never>;

  setTemplate(template: TemplateRef<never>) {
    this.contentTemplate = template;
  }
}
