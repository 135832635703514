import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'components-formly-wrapper-card',
  templateUrl: './formly-wrapper-card.component.html',
  styleUrls: ['./formly-wrapper-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyWrapperCardComponent extends FieldWrapper {}
