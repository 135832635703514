import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  NgModule,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { debounceTime, Observable, startWith, switchMap, withLatestFrom } from 'rxjs';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput, MatInputModule } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  template: `
    <ng-container
      *ngIf="{
        values: (props.values | async),
        filter: filter$ | async
      } as state"
    >
      <input
        #text
        matInput
        [matAutocomplete]="auto"
        [formControl]="$any(formControl)"
        [formlyAttributes]="field"
        [placeholder]="$any(props.placeholder)"
        [errorStateMatcher]="errorStateMatcher"
      />

      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn(state.values)">
        <mat-option *ngIf="props.nullable" [value]="null">---</mat-option>
        <mat-option *ngFor="let option of $any(state.filter)" [value]="option.value">
          <span [innerHTML]="option.label"></span>
          <!--          <span [innerHTML]="option.label | highlight: text.value"></span>-->
        </mat-option>
      </mat-autocomplete>
    </ng-container>
    <ng-template #clearButton>
      <button mat-icon-button matSuffix (click)="clear($event)">
        <mat-icon inline>clear</mat-icon>
      </button>
    </ng-template>
  `,
  styleUrls: ['./formly-autocomplete-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyAutocompleteTypeComponent extends FieldType<FieldTypeConfig> implements AfterViewInit, OnInit {
  @ViewChild('clearButton', { static: true })
  clearButton!: TemplateRef<unknown>;
  @ViewChild(MatInput) formFieldControl!: MatInput;
  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  override defaultOptions = {
    props: {
      nullable: true,
      appearance: 'fill',
    },
    validators: {
      optionNotSelected: {
        expression: (control: UntypedFormControl) => {
          // console.log(control.value);
          return typeof control.value !== 'string';
        },
        message: 'Not a valid option',
      },
    },
  };

  filter$!: Observable<any>;
  displayFn(values: any) {
    return (value: any) => {
      if (this.props.displayFn) {
        const v = this.props.displayFn(value, values);
        return v ?? value;
      }
      return this.value?.label ?? `${this.value}`;
    };
  }
  ngAfterViewInit() {
    // temporary fix for https://github.com/angular/material2/issues/6728
    (<any>this.autocomplete)._formField = this.formField;
  }
  // constructor() {}

  ngOnInit(): void {
    if (this.props.nullable) {
      this.props.suffix = this.clearButton;
    }

    this.filter$ = this.formControl.valueChanges.pipe(
      startWith(''),
      // filter((term) => typeof term === 'string' || !term),
      debounceTime(this.props.debounce ?? 300),
      withLatestFrom(this.props.values as Observable<any>),
      switchMap(
        ([term, options]) => this.props.filter(typeof term === 'string' ? term : '', options) as Observable<any>
      )
    );
    this.props.values.pipe(untilDestroyed(this)).subscribe((v: any) => {
      setTimeout(() => {
        // force the filter to be triggered if the values comes after the component's initialization
        // eslint-disable-next-line no-self-assign
        this.value = this.value;
      });
    });
  }
  readAs(value: any) {
    if (this.props.readAs) {
      return this.props.readAs(value);
    }
    return value;
  }

  displayOption(value: any) {
    if (this.props.displayOption) {
      return this.props.displayOption(value);
    }
    return value;
  }

  clear($event: MouseEvent) {
    this.autocomplete.closePanel();
    this.value = null;
    $event.stopPropagation();
    return false;
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'autocomplete',
          component: FormlyAutocompleteTypeComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [FormlyAutocompleteTypeComponent],
})
export class FormlyAutocompleteFieldModule {}
