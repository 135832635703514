import { createFeatureSelector, createSelector } from '@ngrx/store';
import { partnerFeatureKey, PartnerState } from './partner.reducer';

export const selectFeatureSelector =
  createFeatureSelector<PartnerState>(partnerFeatureKey);

export const selectState = createSelector(
  selectFeatureSelector,
  (state) => state
);

export const selectPartnerLoading = createSelector(
  selectState,
  (state) => state.loading
);

export const selectPartner = createSelector(
  selectState,
  (state) => state.partner
);

export const selectApiKey = createSelector(
  selectState,
  (state) => state.apiKey
);
export const selectOldApiKey = createSelector(
  selectState,
  (state) => state.oldApiKey
);
