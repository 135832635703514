import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { EntityState } from '@ngrx/entity/src/models';
import { Action, createReducer, on } from '@ngrx/store';
import { categoryActions } from './category.actions';
import { Category } from '../../entities/category';
import { StatePaginate } from '@angular-monorepo/shared/util-utils';

export const categoryFeatureKey = 'category';

export const sortComparer = (b: Category, a: Category) => {
  if (a.path > b.path) {
    return -1;
  }
  if (b.path > a.path) {
    return 1;
  }
  return 0;
};

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>({
  selectId: (category) => category.path,
  sortComparer: sortComparer,
});

export interface CategoryState extends EntityState<Category>, StatePaginate {}

const initialState: CategoryState = adapter.getInitialState({
  loading: false,
  count: 0,
});

const entitiesReducer = createReducer(
  initialState,
  // begging action
  on(
    categoryActions.load,
    categoryActions.loadWithPagination,
    categoryActions.add,
    categoryActions.update,
    categoryActions.delete,
    categoryActions.get,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  // error action
  on(
    categoryActions.getFailure,
    categoryActions.updateFailure,
    categoryActions.loadFailure,
    categoryActions.loadWithPaginationFailure,
    categoryActions.addFailure,
    categoryActions.deleteFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
    })
  ),

  on(categoryActions.loadSuccess, (state, { entities }) => {
    return adapter.setAll(entities, {
      ...state,
      loading: false,
    });
  }),
  on(categoryActions.loadWithPaginationSuccess, (state, { paginateEntities }) => {
    return adapter.setAll(paginateEntities.results, {
      ...state,
      count: paginateEntities.count,
      next: paginateEntities.next,
      previous: paginateEntities.previous,
      loading: false,
    });
  }),
  on(categoryActions.addSuccess, (state, { entity }) => {
    return adapter.addOne(entity, {
      ...state,
      loading: false,
    });
  }),
  on(categoryActions.getSuccess, (state, { entity }) => {
    return adapter.upsertOne(entity, {
      ...state,
      loading: false,
    });
  }),
  on(categoryActions.updateSuccess, (state, { entity }) => {
    return adapter.upsertOne(entity, {
      ...state,
      loading: false,
    });
  }),
  on(categoryActions.deleteSuccess, (state, { path }) => {
    return adapter.removeOne(path, {
      ...state,
      loading: false,
    });
  })
);

export function categoryReducer(state: CategoryState = initialState, action: Action): CategoryState {
  return entitiesReducer(state, action);
}
