import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkDragDrop, CdkDropList, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FileValueAccessorDirective } from '../../../file-value-accessor.directive';
import { DropFileModule } from '../../../drop-file.directive';
import { FormlyFieldErrorModule } from '../formly-field-error';
import { NgxFilesizeModule } from 'ngx-filesize';

function __indexOf(collection: any, node: any) {
  return Array.prototype.indexOf.call(collection, node);
}

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}

// @UntilDestroy()
@Component({
  templateUrl: './formly-file-select.component.html',
  styleUrls: ['./formly-file-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFileSelectComponent extends FieldType implements OnInit {
  @ViewChild(FileValueAccessorDirective, { static: true }) fileValueAccessor!: FileValueAccessorDirective;

  override defaultOptions = {
    multiple: false,
  } as FormlyFieldConfig;
  constructor(private cd: ChangeDetectorRef, private sanitizer: DomSanitizer) {
    super();
  }

  // @Output()
  // filesChange = new EventEmitter<File[]>();
  files: any = [];

  clear() {
    this.props.clear();
  }

  onFileDropped(files: any) {
    this.addFiles(files);
  }

  fileBrowseHandler(files: any) {
    this.addFiles(files);
  }

  addFiles(files: FileList) {
    this.fileValueAccessor.addFiles(Array.from(files));
    this.cd.detectChanges();
  }

  addExistingFiles($event: any) {
    // console.log('addExistingFiles', $event);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.fileValueAccessor.files, event.previousIndex, event.currentIndex);
    this.fileValueAccessor.onChange(this.fileValueAccessor.files);
    // this.fileValueAccessor.writeValue(this.files)
  }

  ngOnInit(): void {}

  deleteImage(i: number) {
    // delete image with index i
    this.fileValueAccessor.files.splice(i, 1);
    this.fileValueAccessor.onChange(this.fileValueAccessor.files);
  }
}

@NgModule({
  declarations: [FormlyFileSelectComponent],
  imports: [
    CommonModule,
    NgxFilesizeModule,
    DropFileModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'file-select',
          component: FormlyFileSelectComponent,
        },
      ],
    }),
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    DragDropModule,
    FormlyFieldErrorModule,
  ],
})
export class FormlyFileSelectModule {}
