import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userFeatureKey, UserState } from './user.reducer';

export const selectFeatureSelector = createFeatureSelector<UserState>(userFeatureKey);

export const selectState = createSelector(selectFeatureSelector, (state) => state);

export const selectUser = createSelector(selectState, (state) => state.user);

export const selectUserLoading = createSelector(selectState, (state) => state.loading);

export const selectPartnerId = createSelector(selectUser, (user) => user?.partner_id);
