<ng-container *ngIf="{
  attributeEntities: attributeEntities$ | async,
  attributes: _attributes$ | async
} as state">

<!--  <pre>{{state.attributes | json}}-->
<!--  </pre>-->
<!--  <pre>{{category | json}}-->
<!--  </pre>-->

<!--  <pre>{{state.attributeEntities | json}}-->
<!--  </pre>-->


  <mat-table [dataSource]="_attributes$">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let attribute"> {{ attribute.name }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef>Code</mat-header-cell>
      <mat-cell *matCellDef="let attribute"> {{ attribute.code }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
      <mat-cell *matCellDef="let attribute"> {{ attribute.type }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="add">
      <mat-header-cell *matHeaderCellDef>
      </mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        <mat-checkbox  matTooltip="Add this search facet as an attribute for this product"
                       (change)="addAttribute(attribute)"
                       [checked]="productAttributes.has(attribute.code)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>


<!--    <ng-container matColumnDef="select-all">-->
<!--      <mat-header-cell *matHeaderCellDef>-->
<!--        <mat-checkbox  matTooltip="Select all"-->
<!--                       (change)="toggleAll($event)"-->
<!--                        labelPosition="before">-->
<!--          Toggle all-->
<!--        </mat-checkbox>-->
<!--      </mat-header-cell>-->

<!--    </ng-container>-->

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
<!--    <mat-header-row *matHeaderRowDef="['select-all']; sticky: true"></mat-header-row>-->
    <mat-row *matRowDef="let attribute; columns: displayedColumns;"></mat-row>
  </mat-table>
</ng-container>
