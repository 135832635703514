import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT_CONFIG, EnvironmentConfig, GenericService } from '@angular-monorepo/shared/util-utils';
import { ApiPartnerKey, Partner } from '../entities/partner';

@Injectable({
  providedIn: 'root',
})
export class PartnerServive extends GenericService<Partner> {
  protected constructor(
    protected http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) protected environment: EnvironmentConfig
  ) {
    super(http, environment);
    this.setUrl('/partner/account/');
  }

  backToFront(entity: Partner): Partner {
    const { logo, ...rest } = entity;
    return rest as Partner;
  }

  generateKey(partnerId: number, expiry_date: string | undefined) {
    const data = expiry_date ? { expiry_date } : {};
    return this.http.post<ApiPartnerKey>(`${this.url}${partnerId}/api-key/`, data);
  }
  revokeOldGenerateKey(partnerId: number | undefined, prefix: string | undefined) {
    return this.http.delete(`${this.url}${partnerId}/api-key/${prefix}/`);
  }
  redirectToStripe(url: string) {
    return this.http.get<string>(url);
  }
}
