<ng-template #dialog let-data>
  <h2 matDialogTitle>Edit product categories</h2>

  <mat-dialog-content>
    <components-ui-category-attributes-selection
      [category]="data.category"
      [productAttributes]="data.attributes"
      (attributeAdded)="attributeAdded($event)"
    ></components-ui-category-attributes-selection>
  </mat-dialog-content>
</ng-template>


<div class='flex '>
  <mat-tree [dataSource]="categoriesNode$" [treeControl]='treeControl' class='category-tree w-full'>

    <mat-tree-node *matTreeNodeDef='let node' class='' matTreeNodeToggle
                   matTreeNodeToggleRecursive='true'>
      <div class="tito" *ngIf="!node.isRoot">
        <mat-checkbox class="checklist-leaf-node"
                      [checked]="checklistSelection.isSelected(node?.category?.id)"
                      (change)="toggleSelectionUI(node)">{{node.item}}</mat-checkbox>
        <span>{{node?.category?.name}}</span>

        <ng-container *ngTemplateOutlet='row; context: {node: node}'></ng-container>
      </div>

    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef='let node; when: hasChild'>
      <!--          <div class='mat-tree-node w-full '>-->
      <div class="tito">
        <mat-checkbox [checked]="checklistSelection.isSelected(node?.category?.id)"
                      [indeterminate]="descendantsPartiallySelected(node)"
                      (change)="toggleSelectionUI(node)">{{node.item}}</mat-checkbox>
        <button class="expand-toggle" mat-button matTreeNodeToggle matTreeNodeToggleRecursive='true'>
          <mat-icon>
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
          <span>{{node?.category?.name}}</span>
        </button>
        <ng-container *ngTemplateOutlet='row; context: {node: node}'></ng-container>
      </div>

      <!--          </div>-->

      <!-- There is inline padding applied to this div using styles.
          This padding value depends on the mat-icon-button width.  -->
      <div class="outlet-wrapper"  role='group' [@slideVertical]="treeControl.isExpanded(node) ? 'show' : 'hide'">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>

    </mat-nested-tree-node>
  </mat-tree>
</div>

<components-formly-field-error *ngIf="showError"
                               [field]="field">
</components-formly-field-error>


<ng-template #row let-node='node'>

  <!--  <div class='w-full  flex'>-->

  <div class='flex flex-grow justify-end'>
    <ng-container *ngIf='!node.category?.attributes?.length'>
      <!--      <a [routerLink]="['/category/', node?.category?.id]" class='basis-60 mx-40' mat-icon-button>-->
      <!--        <mat-icon [color]="'accent'"-->
      <!--                  matTooltip='This category does not contain any attributes. Add attributes to enhance the conversion rate. More attributes is better than less.'>-->
      <!--          info-->
      <!--        </mat-icon>-->
      <!--      </a>-->
      <a mat-icon-button>
        <mat-icon [color]="'accent'"
                  matTooltip='This category does not contain any attributes. Add attributes to enhance the conversion rate. More attributes is better than less.'>
          info
        </mat-icon>
      </a>
    </ng-container>
    <ng-container *ngIf='node.category?.attributes?.length'>
      <!--      <a [routerLink]="['/category/', node?.category?.id]" class='basis-60 mx-40' mat-icon-button>-->
      <!--        <mat-icon [color]="'accent'"-->
      <!--                  matTooltip='This category does not contain any attributes. Add attributes to enhance the conversion rate. More attributes is better than less.'>-->
      <!--          info-->
      <!--        </mat-icon>-->
      <!--      </a>-->
      <button mat-stroked-button color="primary" class="view-attributes-button" (click)="viewCategoryAttributes(node.category)">
        View category attributes
      </button>
    </ng-container>
  </div>


  <!--  </div>-->
</ng-template>
