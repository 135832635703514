import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { productVariantReducer, productVariantFeatureKey } from './product-variant.reducer';
import { ProductVariantStoreEffects } from './product-variant.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(productVariantFeatureKey, productVariantReducer),
    EffectsModule.forFeature([ProductVariantStoreEffects]),
  ],
})
export class ProductVariantStoreModule {}
