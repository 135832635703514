import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'button:not([type])',
})
export class ButtonDefaultTypeDirective implements OnInit {
  constructor(private el: ElementRef<HTMLButtonElement>, private renderer: Renderer2) {}

  public ngOnInit() {
    this.renderer.setAttribute(this.el.nativeElement, 'type', 'button');
  }
}
