import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { EntityState } from '@ngrx/entity/src/models';
import { Action, createReducer, on } from '@ngrx/store';
import { attributeActions } from './attributeActions';
import { Attribute } from '../../entities/attribute';
import { SerializableHttpErrorResponse, StatePaginate } from '@angular-monorepo/shared/util-utils';

export const attributesFeatureKey = 'attribute';

export const adapter: EntityAdapter<Attribute> = createEntityAdapter<Attribute>();

export interface AttributesState extends EntityState<Attribute>, StatePaginate {
  errors: SerializableHttpErrorResponse | null;
}

const initialState: AttributesState = adapter.getInitialState({
  loading: false,
  errors: null,
  count: 0,
});

const entitiesReducer = createReducer(
  initialState,
  // begging action
  on(
    attributeActions.load,
    attributeActions.loadWithPagination,
    attributeActions.add,
    attributeActions.update,
    attributeActions.delete,
    attributeActions.get,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  // error action
  on(
    attributeActions.getFailure,
    attributeActions.updateFailure,
    attributeActions.loadFailure,
    attributeActions.loadWithPaginationFailure,
    attributeActions.addFailure,
    attributeActions.deleteFailure,
    (state, { error }) => ({
      ...state,
      errors: error,
      loading: false,
    })
  ),

  on(attributeActions.loadSuccess, (state, { entities }) => {
    return adapter.setAll(entities, {
      ...state,
      loading: false,
    });
  }),
  on(attributeActions.loadWithPaginationSuccess, (state, { paginateEntities }) => {
    return adapter.setAll(paginateEntities.results, {
      ...state,
      count: paginateEntities.count,
      next: paginateEntities.next,
      previous: paginateEntities.previous,
      loading: false,
    });
  }),
  on(attributeActions.addSuccess, (state, { entity }) => {
    return adapter.addOne(entity, {
      ...state,
      loading: false,
    });
  }),
  on(attributeActions.getSuccess, (state, { entity }) => {
    return adapter.upsertOne(entity, {
      ...state,
      loading: false,
    });
  }),
  on(attributeActions.updateSuccess, (state, { entity }) => {
    return adapter.upsertOne(entity, {
      ...state,
      loading: false,
    });
  }),
  on(attributeActions.deleteSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      loading: false,
    });
  })
);

export function attributeReducer(state: AttributesState = initialState, action: Action): AttributesState {
  return entitiesReducer(state, action);
}
