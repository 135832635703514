import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { EntityState } from '@ngrx/entity/src/models';
import { Action, createReducer, on } from '@ngrx/store';
import { exportationActions } from './exportation.actions';
import { Exportation } from '../../entities/exportation';
import { SerializableHttpErrorResponse, StatePaginate } from '@angular-monorepo/shared/util-utils';

export const exportationFeatureKey = 'exportation';

export const adapter: EntityAdapter<Exportation> = createEntityAdapter<Exportation>();

export interface ExportationState extends EntityState<Exportation>, StatePaginate {
  errors?: SerializableHttpErrorResponse;
}

const initialState: ExportationState = adapter.getInitialState({
  loading: false,
  count: 0,
});
const { selectAll } = adapter.getSelectors();
const entitiesReducer = createReducer(
  initialState,
  on(
    exportationActions.load,
    exportationActions.loadWithPagination,
    exportationActions.add,
    exportationActions.get,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  // error action
  on(
    exportationActions.getFailure,
    exportationActions.loadFailure,
    exportationActions.loadWithPaginationFailure,
    exportationActions.addFailure,
    (state, { error }) => ({
      ...state,
      errors: error,
      loading: false,
    })
  ),

  on(exportationActions.loadSuccess, (state, { entities }) => {
    return adapter.setAll(entities, {
      ...state,
      loading: false,
    });
  }),
  on(exportationActions.loadWithPaginationSuccess, (state, { paginateEntities }) => {
    return adapter.setAll(paginateEntities.results, {
      ...state,
      count: paginateEntities.count,
      next: paginateEntities.next,
      previous: paginateEntities.previous,
      loading: false,
    });
  }),
  on(exportationActions.addSuccess, (state, { entity }) => {
    return adapter.setAll([entity, ...selectAll(state)], { ...state, count: state.count + 1, loading: false });
  }),
  on(exportationActions.getSuccess, (state, { entity }) => {
    return adapter.upsertOne(entity, {
      ...state,
      loading: false,
    });
  })
);

export function exportationReducer(state: ExportationState = initialState, action: Action): ExportationState {
  return entitiesReducer(state, action);
}
