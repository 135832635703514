import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthenticationFeatureAuthenticationRoutingModule } from './authentication-feature-authentication.routing.module';
import { SharedUiComponentsModule } from '@angular-monorepo/shared/ui-components';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '@angular-monorepo/shared/util-utils';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    AuthenticationFeatureAuthenticationRoutingModule,
    SharedUiComponentsModule,
  ],
  declarations: [LoginComponent, RegisterComponent],
  exports: [LoginComponent, RegisterComponent],
})
export class AuthenticationFeatureAuthenticationModule {}
