<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  {{ data.content }}
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button  [mat-dialog-close]="false">
    Cancel
  </button>
  <button
    mat-raised-button
    [color]="'warn'"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Yes, leave page
  </button>
</div>
