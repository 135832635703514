import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { MatIconRegistry } from '@angular/material/icon';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'components-formly-prefix-suffix-wrapper',
  templateUrl: './formly-prefix-suffix-wrapper.component.html',
  styleUrls: ['./formly-prefix-suffix-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyPrefixSuffixWrapperComponent extends FieldWrapper implements AfterViewInit {
  @ViewChild('matPrefix') matPrefix!: TemplateRef<any>;
  @ViewChild('matSuffix') matSuffix!: TemplateRef<any>;

  ngAfterViewInit() {
    if (this.matPrefix) {
      Promise.resolve().then(() => (this.props.prefix = this.matPrefix));
    }

    if (this.matSuffix) {
      Promise.resolve().then(() => (this.props.suffix = this.matSuffix));
    }
    // avoid the glitch of prefix wrapper which show icons and units only after the first change detection
    setTimeout(() => {
      this.cd.markForCheck();
    });
  }

  testIcon(icon: string) {
    return this.iconReg.getNamedSvgIcon(icon).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  constructor(private iconReg: MatIconRegistry, private cd: ChangeDetectorRef) {
    super();
  }
}
