<div class="flex h-screen justify-center items-center text-center">
  <div class="flex flex-auto h-5/6 border-r-2">
    <img class="w-96 m-auto" alt="logo" src="/assets/dommarket-logo.svg" />
  </div>
  <div class="flex flex-auto">
    <div class="flex-col m-auto items-center">
      <img class="w-2/12 m-auto mb-12" src="/assets/dommarket-logo-mini.svg" />
      <ng-content></ng-content>
    </div>
  </div>
</div>
