import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Integrations } from '@sentry/tracing';

if (environment.production) {
  Sentry.init({
    dsn: environment.sentry.DSN,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'https://backoffice-qa.dommarket.ovh',
          'https://backoffice-demo.dommarket.ovh',
          'https://qa.dommarket.ovh',
          'https://demo.dommarket.ovh',
          `${environment.api.scheme}://${environment.api.baseDomain}`,
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
