import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, ImportationState, importationFeatureKey } from './importation.reducer';

const { selectIds, selectAll, selectEntities, selectTotal } = adapter.getSelectors();

const selectFeatureSelector = createFeatureSelector<ImportationState>(importationFeatureKey);

const selectState = createSelector(selectFeatureSelector, (state) => state);

const selectImportationIds = createSelector(selectState, selectIds);
const selectImportationAll = createSelector(selectState, selectAll);
const selectImportationTotal = createSelector(selectState, selectTotal);

const selectImportationEntities = createSelector(selectState, selectEntities);
const selectImportationLoading = createSelector(selectState, (state) => state.loading);

const selectPagination = createSelector(selectFeatureSelector, (state) => ({
  next: state.next,
  previous: state.previous,
  count: state.count,
}));
const selectSelectedEntityId = createSelector(selectFeatureSelector, (state) => state.selectedEntityId);

const selectSelectedEntity = createSelector(
  selectImportationEntities,
  selectSelectedEntityId,
  (entities, selectedEntityId) => {
    if (selectedEntityId) {
      return entities[selectedEntityId];
    } else {
      return undefined;
    }
  }
);

export const importationSelectors = {
  selectImportationIds,
  selectImportationAll,
  selectImportationTotal,
  selectImportationEntities,
  selectImportationLoading,
  selectPagination,
  selectSelectedEntityId,
  selectSelectedEntity,
};
