import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, ExportationState, exportationFeatureKey } from './exportation.reducer';
import { DateTime } from 'luxon';

const { selectIds, selectAll, selectEntities, selectTotal } = adapter.getSelectors();

const selectFeatureSelector = createFeatureSelector<ExportationState>(exportationFeatureKey);

const selectState = createSelector(selectFeatureSelector, (state) => state);

const selectExportationIds = createSelector(selectState, selectIds);
const selectExportationAll = createSelector(selectState, selectAll);

const selectExportationTotal = createSelector(selectState, selectTotal);

const selectExportationEntities = createSelector(selectState, selectEntities);
const selectExportationLoading = createSelector(selectState, (state) => state.loading);

const selectPagination = createSelector(selectFeatureSelector, (state) => ({
  next: state.next,
  previous: state.previous,
  count: state.count,
}));
const selectSelectedEntityId = createSelector(selectFeatureSelector, (state) => state.selectedEntityId);

const selectSelectedEntity = createSelector(
  selectExportationEntities,
  selectSelectedEntityId,
  (entities, selectedEntityId) => {
    if (selectedEntityId) {
      return entities[selectedEntityId];
    } else {
      return undefined;
    }
  }
);

export const exportationSelectors = {
  selectExportationIds,
  selectExportationAll,
  selectExportationTotal,
  selectExportationEntities,
  selectExportationLoading,
  selectPagination,
  selectSelectedEntityId,
  selectSelectedEntity,
};
