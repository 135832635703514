export enum StripeStatusCode {
  UNREGISTERED = 1,
  INCOMPLETE,
  NONE,
  REJECTED,
  PENDING,
  INFORMATION_REQUIRED,
  ERROR,
}

export type StripeStatus = {
  code: StripeStatusCode;
  reason: string; //(only present for REJECTED and PENDING),
  currently_due: string[]; //(see Stripe doc) (only present for INFORMATION_REQUIRED),
  past_due: string[]; //(see Stripe doc) (only present present for INFORMATION_REQUIRED),
  current_deadline: Date; //(only present for INFORMATION_REQUIRED),
  failed_reason: string[]; //(only present for INFORMATION_REQUIRED),
};

export interface ApiPartnerKey {
  prefix: string;
  partner: number;
  name: string;
  expiry_date: string;
  key?: string;
}

export interface ApiPartnerKey {
  prefix: string;
  partner: number;
  name: string;
  expiry_date: string;
  key?: string;
}

export interface Partner {
  readonly id: number;
  stripe_status: StripeStatus;
  api_key: ApiPartnerKey | undefined;
  code: string;
  name: string;
  vat_number: string;
  eori_number: string;
  siren_number: string;
  nic_number: string;
  stripe_account_id: string;
  is_stripe_ready: boolean;
  manual_validation: boolean;
  cgv: string;
  logo: string;
  minimum_shipping_delay_dommarket_warehouse: number;
  maximum_shipping_delay_dommarket_warehouse: number;
}
