import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

const selectAuthState = createFeatureSelector<fromAuth.AuthState>(fromAuth.authFeatureKey);

export const selectIsLoading = createSelector(selectAuthState, (state: fromAuth.AuthState) => state.isLoading);

export const selectTokens = createSelector(selectAuthState, (state: fromAuth.AuthState) => state.tokens);

export const selectAccessToken = createSelector(selectTokens, (token) => token?.access || null);
export const selectIsLogged = createSelector(selectAuthState, (state: fromAuth.AuthState) => state?.isLogged);

export const selectLoginError = createSelector(selectAuthState, (state: fromAuth.AuthState) => state.loginError);

export const selectUser = createSelector(selectAuthState, (state: fromAuth.AuthState) => state.user);

const selectRedirectUrl = createSelector(selectAuthState, (state: fromAuth.AuthState) => state.redirectUrl);

// // TODO replace deprecated method, see https://github.com/ngrx/platform/issues/2980
// const selectHasPermission = createSelector(
//   selectAuthState,
//   (state: fromAuth.AuthState, permission: Permission) => {
//     return (
//       !!state.user && state.user.permissions.includes(permission.toString())
//     );
//   }
// );

export const authSelectors = {
  selectAuthState,
  selectIsLoading,
  selectTokens,
  selectIsLogged,
  selectLoginError,
  selectUser,
  selectRedirectUrl,
  // selectHasPermission,
  selectAccessToken,
};
