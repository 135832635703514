import { createAction, props } from '@ngrx/store';
import {
  createFailureAction,
  createSuccessAction,
  ListParams,
  Paginate,
  SerializableHttpErrorResponse,
} from '@angular-monorepo/shared/util-utils';
import { Exportation } from '../../entities/exportation';

export const exportationActions = {
  load: createAction('[Exportation/API] Load Exportations', props<{ params?: ListParams }>()),
  loadSuccess: createAction('[Exportation/API] Load Exportations Success', props<{ entities: Exportation[] }>()),
  loadFailure: createAction(
    '[Exportation/API] Load Exportations Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),

  loadWithPagination: createAction(
    '[Exportation/API] Load with Pagination Exportations',
    props<{ params?: ListParams }>()
  ),
  loadWithPaginationSuccess: createAction(
    '[Exportation/API] Load with Pagination Exportations Success',
    props<{ paginateEntities: Paginate<Exportation[]> }>()
  ),
  loadWithPaginationFailure: createAction(
    '[Exportation/API] Load with Pagination Exportations Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),

  get: createAction('[Exportation/API] Get Exportation', props<{ id: number }>()),
  getSuccess: createAction('[Exportation/API] Get Exportation Success', props<{ entity: Exportation }>()),
  getFailure: createAction(
    '[Exportation/API] Get Exportation Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),
  selectEntity: createAction('[Exportation/API] Select Exportation', props<{ id: string | number }>()),

  add: createAction('[Exportation/API] Add Exportation'),
  addSuccess: createSuccessAction('[Exportation/API] Add Exportation Success', props<{ entity: Exportation }>(), {
    title: 'Exportation started',
  }),
  addFailure: createFailureAction(
    '[Exportation/API] Add Exportation Failure',
    props<{ error: SerializableHttpErrorResponse }>(),
    {
      title: 'Failed to start exportation',
    }
  ),
};
