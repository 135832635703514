
<ng-container #fieldComponent></ng-container>

<ng-template #matSuffix>
      <span *ngIf="props.units">{{ props.units }}</span>
</ng-template>

<ng-template #matPrefix>
      <mat-icon *ngIf="props.icon">{{props.icon}}</mat-icon>
</ng-template>
