import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListParams, Paginate, TemplateT } from './interface-generic.model';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { EnvironmentConfig } from '../environment.model';

export interface PaginationParams {
  page?: number;
  size?: number;
  search?: string;
  ordering?: string;
  direction?: string;
  start?: DateTime | string;
  end?: DateTime | string;
}

export const makeOptions = (params: ListParams) => {
  let options = new HttpParams();
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      if (Array.isArray(params[key])) {
        (params[key] as Array<string | number>).forEach((element) => {
          if (element !== null && element !== undefined) {
            options = options.append(key, element.toString());
          }
        });
      } else {
        if (params[key] !== null && params[key] !== undefined) {
          options = options.append(key, params[key].toString());
        }
      }
    }
  }
  return options;
};

export function serializePaginationParams(
  params: { [key: string]: any } | PaginationParams,
  orderingMap: Record<string, string> = {}
) {
  const { direction, ...restParams } = params;
  if (direction) {
    return {
      ...restParams,
      ordering: (direction === 'asc' ? '' : '-') + (orderingMap[params.ordering] || params.ordering),
    };
  } else {
    return restParams;
  }
}

/**
 * @template B backend type
 * @template F frontend type
 */
export abstract class GenericService<
  B extends TemplateT,
  F extends TemplateT = B,
  ListParam = Record<string, unknown>,
  PaginateModel extends any[] = B[]
> {
  private _url = '';
  public get url() {
    return this._url;
  }
  public set url(value) {
    this._url = value;
  }
  protected constructor(protected http: HttpClient, protected environment: EnvironmentConfig) {}

  backToFront(entity: B) {
    return entity as unknown as F;
  }

  frontToBack(entity: Partial<F>) {
    return entity as unknown as B;
  }

  computeUrl(suffix: string) {
    return `${this.environment.api.scheme}://${this.environment.api.baseDomain}${this.environment.api.suffixDomain}${this.environment.api.prefix}${suffix}`;
  }
  setUrl(url: string) {
    this.url = this.computeUrl(url);
  }

  create(entity: Partial<F>, url = this.url) {
    return this.http.post<B>(url, this.frontToBack(entity)).pipe(map((a) => this.backToFront(a)));
  }

  list(params = {} as ListParams, url = this.url) {
    return this.http
      .get<B[]>(url, { params: params ? makeOptions(params) : {} })
      .pipe(map((entitys) => entitys.map((entity) => this.backToFront(entity))));
  }

  listWithPagination(params = {} as ListParams, url = this.url) {
    return this.http
      .get<Paginate<PaginateModel>>(url, {
        params: params ? makeOptions(params) : {},
      })
      .pipe(map((r) => this.mapListResultWithPagination(r)));
  }
  mapListResultWithPagination(r: Paginate<PaginateModel>) {
    return {
      ...r,
      results: r.results.map((result) => this.backToFront(result)) as F[],
    };
  }

  computeGetUrl(id: number, params = {} as ListParams) {
    return `${this.url}${id}/`;
  }

  get(id: number, params = {} as ListParams) {
    return this.http
      .get<B>(this.computeGetUrl(id, params), { params: params ? makeOptions(params) : {} })
      .pipe(map((a) => this.backToFront(a)));
  }

  update(entity: F, url = this.url) {
    return this.http.patch<B>(`${url}${entity.id}/`, this.frontToBack(entity)).pipe(map((a) => this.backToFront(a)));
  }

  delete(id: number | string) {
    return this.http.delete<B>(`${this.url}${id}/`);
  }

  updateOrCreate(entity: F, url = this.url) {
    if (entity.id) {
      return this.update(entity, url);
    } else {
      return this.create(entity, url);
    }
  }
}
