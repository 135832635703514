import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ENVIRONMENT_CONFIG,
  EnvironmentConfig,
  FileDownloader,
  GenericService,
} from '@angular-monorepo/shared/util-utils';
import { Exportation } from '../entities/exportation';

@Injectable({
  providedIn: 'root',
})
export class ExportationService extends GenericService<Exportation> {
  protected constructor(
    protected http: HttpClient,
    private fileDownloader: FileDownloader,
    @Inject(ENVIRONMENT_CONFIG) protected environment: EnvironmentConfig
  ) {
    super(http, environment);
    this.setUrl('/partner/exportation/');
  }

  downloadResult(exportation: Exportation) {
    this.fileDownloader.get(`${this.url}${exportation.id}/download_result/`, `report_result`).subscribe();
  }
  // create(entity: Importation, url: string = this.url) {
  //   const formData = new FormData();
  //   formData.append('type', entity.type);
  //   formData.append('imported_file', entity.imported_file);
  //
  //   return this.http.post<Importation>(url, formData);
  // }

  // exportFile() {
  //   this.fileDownloader.get(this.computeUrl('/partner/exportation/'), 'exported_data').subscribe();
  // }
}
