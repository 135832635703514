<components-account-base>
  <h1 class="text-color mat-primaru pb-10">
    Please fill all input fields to create your account.
  </h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
    <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
    <button mat-raised-button type="submit" class="w-4/12 flex" color="primary">
      Sign Up
    </button>
    <a
      class="mt-10 flex justify-center"
      [routerLink]="['/authentication/login/']"
      >Already have an account? Sign in</a
    >
  </form>
</components-account-base>
