import { NgModule } from '@angular/core';
import {
  maxlengthValidationMessage,
  maxValidationMessage,
  minlengthValidationMessage,
  minValidationMessage,
  SharedModule,
} from '@angular-monorepo/shared/util-utils';
import {
  FormlyAccordionGroupComponent,
  FormlyArrayFieldComponent,
  FormlyAutocompleteTypeComponent,
  formlyAutoPrefixSuffixWrapperExtension,
  FormlyCheckboxNullableComponent,
  FormlyCountryModule,
  FormlyFieldErrorComponent,
  FormlyFileSelectComponent,
  FormlyFileSelectModule,
  formlyMaterialExtension,
  FormlyPrefixSuffixWrapperComponent,
  FormlyProductPackagesFieldComponent,
  FormlyRichTextEditorComponent,
  FormlyStepperModule,
  FormlyWrapperCardComponent,
} from '@angular-monorepo/shared/ui-components';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyProductCategorySelectionModule } from '../../../../libs/shared/ui-components/src/lib/formly/fields/formly-product-category-selection/formly-product-category-selection.module';
import { FormlyAutocompleteFieldModule } from '../../../../libs/shared/ui-components/src/lib/formly/fields/formly-autocomplete/formly-autocomplete-type.component';
import { FormlyTooltipWrapperModule } from '../../../shared-ui-components-e2e/src/integration/formly/wrappers/tooltip/tooltip-wrapper.component';

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule.forRoot({
      validationMessages: [
        { name: 'server', message: (err, _) => err },
        { name: 'required', message: 'This field is required' },
        { name: 'minLength', message: minlengthValidationMessage },
        { name: 'maxLength', message: maxlengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
      ],
      types: [
        { name: 'formly-array', component: FormlyArrayFieldComponent },
        { name: 'accordion', component: FormlyAccordionGroupComponent },
        {
          name: 'globalErrors',
          component: FormlyFieldErrorComponent,
        },
        { name: 'rich-text', component: FormlyRichTextEditorComponent },
        {
          name: 'product-packages',
          component: FormlyProductPackagesFieldComponent,
        },
        {
          name: 'checkbox-nullable',
          component: FormlyCheckboxNullableComponent,
        },
        // {
        //   name: 'country-select',
        //   component: FormlyCountryTypeOldComponent,
        //   wrappers: ['form-field'],
        //   defaultOptions: {
        //     props: {
        //       appearance: 'fill',
        //     },
        //   },
        // },
      ],
      extensions: [
        {
          name: 'default-types',
          extension: {
            prePopulate(field: FormlyFieldConfig) {
              if (!field.type && field.fieldArray) {
                field.type = 'formly-array';
              }
            },
          },
        },
        { name: 'material-overrides', extension: formlyMaterialExtension },
        {
          name: 'prefix-suffix',
          extension: formlyAutoPrefixSuffixWrapperExtension,
        },
      ],
      wrappers: [
        {
          name: 'prefix-suffix',
          component: FormlyPrefixSuffixWrapperComponent,
        },
        {
          name: 'card',
          component: FormlyWrapperCardComponent,
        },
      ],
      extras: {
        // default showError can be found here : https://github.com/ngx-formly/ngx-formly/blob/98850679c61912d20f431101dd8686ae37779f70/src/core/src/lib/services/formly.config.ts
        // if the field is untouched BUT it has a value, we want to show the error.
        // This can happen if the validation change days after the data is already saved
        showError: (field) => {
          return !!field.formControl?.invalid;
          // (
          // !!field.formControl?.invalid &&
          //   (field.formControl?.touched ||
          //     !!field?.formControl?.value ||
          //     field.options.parentForm?.submitted ||
          //     !!field.field.validation?.show)
          // );
        },
      },
    }),
    FormlyProductCategorySelectionModule,
    FormlyAutocompleteFieldModule,
    FormlyCountryModule,
    FormlyStepperModule,
    FormlyFileSelectModule,
    FormlyTooltipWrapperModule,
  ],
  exports: [FormlyModule],
})
export class MyFormlyModule {}
