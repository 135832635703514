import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT_CONFIG, EnvironmentConfig, GenericService } from '@angular-monorepo/shared/util-utils';
import { Category } from '../entities/category';

@Injectable({
  providedIn: 'root',
})
export class CategoryServive extends GenericService<Category> {
  protected constructor(
    protected http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) protected environment: EnvironmentConfig
  ) {
    super(http, environment);
    this.setUrl('/catalogue/category/');
  }
  frontToBack(entity: Partial<Category>) {
    const internal_seller_codes = entity.internal_seller_codes ? entity.internal_seller_codes.split(',') : null;
    return {
      ...entity,
      internal_seller_codes: internal_seller_codes,
    } as unknown as Category;
  }
  backToFront(entity: Category) {
    const internal_seller_codes = entity.internal_seller_codes
      ? (entity.internal_seller_codes as unknown as Array<string>).join(',')
      : null;
    return {
      ...entity,
      internal_seller_codes: internal_seller_codes,
    } as unknown as Category;
  }
}
