import { Directive, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  // tslint:disable-next-line
  exportAs: 'FileValueAccessor',
  selector: 'input[type=file]',
  host: {
    '(blur)': 'onTouched()',
  },
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: FileValueAccessorDirective, multi: true }],
})
// https://github.com/angular/angular/issues/7341
export class FileValueAccessorDirective implements ControlValueAccessor, OnDestroy {
  files: any[] = [];
  onChange = (_: any) => {};
  onTouched = () => {};

  writeValue(value: any[]) {
    this.files = value ?? [];
  }
  addFiles(event: any[]) {
    this.writeValue([...this.files, ...event]);
    this.onChange(this.files);
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  constructor() {
    // console.log('FileValueAccessorDirective');
  }

  ngOnDestroy(): void {
    // console.log('FileValueAccessorDirective.ngOnDestroy');
  }
}
