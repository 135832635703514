import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { EntityState } from '@ngrx/entity/src/models';
import { Action, createReducer, on } from '@ngrx/store';
import { productVariantActions } from './product-variant.actions';
import { SerializableHttpErrorResponse, StatePaginate } from '@angular-monorepo/shared/util-utils';
import { ProductVariant } from '../../entities/product';

export const productVariantFeatureKey = 'productVariant';

export const adapter: EntityAdapter<ProductVariant> = createEntityAdapter<ProductVariant>();

export interface ProductVariantState extends EntityState<ProductVariant>, StatePaginate {
  errors?: SerializableHttpErrorResponse;
}

const initialState: ProductVariantState = adapter.getInitialState({
  loading: false,
  count: 0,
});

const entitiesReducer = createReducer(
  initialState,
  // begging action
  on(
    productVariantActions.load,
    productVariantActions.loadWithPagination,
    productVariantActions.add,
    productVariantActions.update,
    productVariantActions.delete,
    productVariantActions.get,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  // error action
  on(
    productVariantActions.getFailure,
    productVariantActions.updateFailure,
    productVariantActions.loadFailure,
    productVariantActions.loadWithPaginationFailure,
    productVariantActions.addFailure,
    productVariantActions.deleteFailure,
    (state, { error }) => ({
      ...state,
      errors: error,
      loading: false,
    })
  ),

  on(productVariantActions.loadSuccess, (state, { entities }) => {
    // console.log()
    const variants = entities.map((variant) => {
      const { measures, ...rest } = variant;
      return {
        ...rest,
        measures: {
          ...measures,
          item: {
            ...variant.measures.item,
          },
          packages$: variant.measures.packages.map((pack) => ({ ...pack })),
        },
      } as ProductVariant;
    });
    return adapter.setAll(variants, {
      ...state,
      loading: false,
    });
  }),
  on(productVariantActions.loadWithPaginationSuccess, (state, { paginateEntities }) => {
    return adapter.setAll(paginateEntities.results, {
      ...state,
      count: paginateEntities.count,
      next: paginateEntities.next,
      previous: paginateEntities.previous,
      loading: false,
    });
  }),
  on(productVariantActions.addSuccess, (state, { entity }) => {
    return adapter.addOne(entity, {
      ...state,
      loading: false,
    });
  }),
  on(productVariantActions.getSuccess, (state, { entity }) => {
    return adapter.upsertOne(entity, {
      ...state,
      loading: false,
    });
  }),
  on(productVariantActions.updateSuccess, (state, { entity }) => {
    return adapter.upsertOne(entity, {
      ...state,
      loading: false,
    });
  }),
  on(productVariantActions.deleteSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      loading: false,
    });
  })
);

export function productVariantReducer(state: ProductVariantState = initialState, action: Action): ProductVariantState {
  return entitiesReducer(state, action);
}
