<mat-card>
  <mat-card-header *ngIf="props.cardTitle">
    <mat-card-title>{{ props.cardTitle }}</mat-card-title>
    <mat-card-subtitle *ngIf="props.cardSubtitle">{{
      props.cardSubtitle
      }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <ng-container #fieldComponent></ng-container>
  </mat-card-content>
</mat-card>
