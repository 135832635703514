import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { exportationActions, exportationSelectors } from '../+state/exportation';
import { ListParams } from '@angular-monorepo/shared/util-utils';
import { Exportation } from '../entities/exportation';
import { ExportationService } from '../infrastructure/exportation.service';

@Injectable({ providedIn: 'root' })
export class ExportationListFacade {
  paginator$ = this.store.select(exportationSelectors.selectPagination);
  exportations$ = this.store.select(exportationSelectors.selectExportationAll);

  loadPaginate(params?: ListParams) {
    this.store.dispatch(exportationActions.loadWithPagination({ params }));
  }

  startExportation() {
    this.store.dispatch(exportationActions.add());
  }

  downloadResult(exportation: Exportation) {
    this.exportationService.downloadResult(exportation);
  }

  constructor(private store: Store, private exportationService: ExportationService) {}
}
