import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../infrastructure/auth.service';

import { Tokens } from '../entities/user';
import { SerializableHttpErrorResponse } from '@angular-monorepo/shared/util-utils';
import { refreshFailure, refreshSuccess } from '../+state/auth/auth.actions';
import { authSelectors, AuthState } from '../+state/auth';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private tokenState$ = new BehaviorSubject<string | null>(null);

  constructor(private store: Store<AuthState>, private helper: JwtHelperService, private auth: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // exclude auth urls
    if (request.url.includes('token')) {
      return next.handle(request);
    }

    if (!this.refreshTokenInProgress) {
      return this.store.pipe(
        select(authSelectors.selectTokens),
        first(),
        switchMap((oldTokens: Tokens | null) => {
          if (
            oldTokens != null &&
            this.helper.isTokenExpired(oldTokens.access) &&
            !this.helper.isTokenExpired(oldTokens.refresh)
          ) {
            this.refreshTokenInProgress = true;
            return this.auth.refresh(oldTokens).pipe(
              tap((tokens: Tokens) => {
                this.store.dispatch(refreshSuccess({ tokens }));
              }),
              catchError((err: SerializableHttpErrorResponse) => {
                this.store.dispatch(refreshFailure({ error: err }));
                return of();
              }),
              switchMap(() => {
                // since catchError will return an observable,
                // this code will also be executed in case of error
                this.tokenState$.next('refreshed');
                this.refreshTokenInProgress = false;
                this.tokenState$.next(null);
                return next.handle(request);
              })
            );
          } else {
            return next.handle(request);
          }
        })
      );
    }

    // if a refresh is in progress, wait for it to finish before sending the request
    // (to which the JWT interceptor will add auth token)
    return this.tokenState$.pipe(
      filter((token) => token != null),
      first(),
      switchMap(() => {
        return next.handle(request);
      })
    );
  }
}
