import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

export function skipEmpty<T>() {
  return function skipEmptyOperator(source$: Observable<null | undefined | T>): Observable<T> {
    return source$.pipe(filter(isNotNullOrUndefined));
  };
}

export function asNumber<T>() {
  return function asNumberOperator(source$: Observable<T>) {
    return source$.pipe(
      filter((id) => {
        return !isNaN(Number(id));
      }),
      map((id) => Number(id))
    );
  };
}
