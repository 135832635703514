import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BlobErrorHttpInterceptor, ENVIRONMENT_CONFIG } from '@angular-monorepo/shared/util-utils';
import { QuillModule } from 'ngx-quill';
import { MyFormlyModule } from './my-formly.module';
import { AuthenticationDomainModule, JwtConfigModule } from '@angular-monorepo/authentication/domain';
import { NavigationActionTiming, routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { AttributesDomainModule } from '@angular-monorepo/attributes/domain';
import { AccountDomainModule } from '@angular-monorepo/account/domain';
import { CategoryDomainModule } from '@angular-monorepo/category/domain';
import { NotificationsDomainModule } from '@angular-monorepo/notifications/domain';
import { ProductDomainModule } from '@angular-monorepo/product/domain';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { YesNoModule } from '@angular-monorepo/shared/ui-components';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorDefaultOptions } from '@angular/material/paginator';
import { BatchFilesDomainModule } from '@angular-monorepo/batch-files/domain';
import Quill from 'quill';
import { NotificationAnimationType, SimpleNotificationsModule } from 'angular2-notifications';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

const domains = [
  AttributesDomainModule,
  AuthenticationDomainModule,
  AccountDomainModule,
  CategoryDomainModule,
  NotificationsDomainModule,
  ProductDomainModule,
  BatchFilesDomainModule,
];

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    StoreModule.forRoot({
      router: routerReducer,
    }),
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      name: 'Dommarket-backoffice',
    }),
    JwtConfigModule,
    HttpClientModule,
    ScrollingModule,

    QuillModule.forRoot({
      // https://quilljs.com/docs/formats/
      formats: ['bold', 'italic', 'link', 'strike', 'underline', 'header', 'list', 'align'],
    }),
    MyFormlyModule,
    YesNoModule,
    SimpleNotificationsModule.forRoot({
      position: ['top', 'right'],
      showProgressBar: true,
      animate: NotificationAnimationType.FromRight,
      clickToClose: true,
      clickIconToClose: true,
      lastOnBottom: true,
      timeOut: 15_000,
    }),
    ...domains,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: environment.production,
        logErrors: true,
      }),
    },
    // register blob interceptor AFTER sentry handler
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },

    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ENVIRONMENT_CONFIG,
      useValue: environment,
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        pageSizeOptions: [5, 10, 25, 50, 100],
        pageSize: 10,
      } as MatPaginatorDefaultOptions,
    },
  ],
})
export class AppCoreModule {}
