import { ChangeDetectionStrategy, Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SharedFeatureSafeHtmlPipeModule } from '@angular-monorepo/shared/feature-safe-html-pipe';

@Component({
  template: `
    <mat-stepper linear>
      <mat-step *ngFor="let step of field.fieldGroup; let index = index; let last = last" [completed]="isValid(step)">
        <ng-template matStepLabel>{{ step.props?.label }}</ng-template>
        <div *ngIf="step?.props?.helper_text" [innerHTML]="step.props?.helper_text"></div>
        <formly-field [field]="step"></formly-field>

        <div style="display: flex; gap: 1em">
          <button matStepperPrevious *ngIf="index !== 0" mat-stroked-button color="primary" type="button">Back</button>

          <button
            matStepperNext
            *ngIf="!last"
            mat-raised-button
            color="primary"
            type="button"
            [disabled]="!isValid(step)"
          >
            Next
          </button>
          <div style="flex: 1"></div>
          <button *ngIf="last" mat-raised-button color="primary" [disabled]="!form.valid" type="submit">
            {{ props.submit_text ?? 'Submit' }}
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyStepperComponent extends FieldType implements OnInit {
  @ViewChild(MatStepper) stepper!: MatStepper;

  ngOnInit() {
    this.props.componentInstance = this;
    setTimeout(() => {
      this.stepper.steps.forEach((step) => {
        step.interacted = true;
        // step.completed = true;
      });
    });
  }

  next() {
    this.stepper.next();
  }

  isValid(field: FormlyFieldConfig): boolean {
    // return true;
    if (field.key) {
      return field?.formControl?.valid ?? false;
    }

    return field.fieldGroup ? field.fieldGroup.every((f) => this.isValid(f)) : true;
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatStepperModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'stepper',
          component: FormlyStepperComponent,
        },
      ],
    }),
    SharedFeatureSafeHtmlPipeModule,
    MatButtonModule,
  ],
  declarations: [FormlyStepperComponent],
})
export class FormlyStepperModule {}
