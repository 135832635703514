import { createAction, props } from '@ngrx/store';
import { Credentials, Tokens, User } from '../../entities/user';
import {
  createFailureAction,
  createSuccessAction,
  SerializableHttpErrorResponse,
} from '@angular-monorepo/shared/util-utils';

export const initAuth = createAction('[Auth] Init Auth');

export const login = createAction('[Auth] Login', props<{ credentials: Credentials }>());
export const loginSuccess = createAction('[Auth] Login Success', props<{ tokens: Tokens }>());
export const loginFailure = createFailureAction(
  '[Auth] Login Failure',
  props<{ error: SerializableHttpErrorResponse }>(),
  {
    content: 'Failed to log in',
  }
);

export const resetLoginError = createAction('[Auth] Reset Login Error');

export const logout = createAction('[Auth] Logout');
export const logoutSuccess = createSuccessAction('[Auth] Logout Success', undefined, {
  title: 'You have been automatically disconnected',
});

export const refreshSuccess = createAction('[Auth] Refresh Success', props<{ tokens: Tokens }>());
export const refreshFailure = createFailureAction(
  '[Auth] Refresh Failure',
  props<{ error: SerializableHttpErrorResponse }>(),
  {
    title: 'Could not refresh token',
  }
);

export const setNewRefreshDate = createAction(
  '[Auth] Set New Refresh Date',
  props<{ refreshExpirationDate: string | null }>()
);

export const autoLogout = createSuccessAction('[Auth] Auto Logout', undefined, {
  title: 'You have been automatically disconnected',
});

export const setUser = createAction('[Auth] Set User', props<{ user: User }>());

export const setRedirectUrl = createAction('[Auth] Set Redirect Url', props<{ redirectUrl: string }>());

export const register = createAction(
  '[Auth] Register account',
  props<{
    id: string;
    data: { email: string; password: string; token: string };
  }>()
);
export const registerSuccess = createSuccessAction('[Auth] Register account Success', undefined, {
  title: 'Account registered',
});
export const registerFailure = createFailureAction(
  '[Auth] Register account Failure',
  props<{ error: SerializableHttpErrorResponse }>(),
  {
    title: 'Failed to register account',
  }
);
