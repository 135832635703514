// taken from https://stackblitz.com/edit/ng-material-tree-nested-animated?file=src%2Fapp%2Ftree-nested-overview-example.ts

import { animate, state, style, transition, trigger } from '@angular/animations';

export const slideVerticalAnimation = trigger('slideVertical', [
  state(
    'hide',
    style({
      height: 0,
    })
  ),
  state(
    'show',
    style({
      height: '*',
    })
  ),
  transition('* => *', [animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
]);
