import { Product } from '../../entities/product';
import { createAction, props } from '@ngrx/store';
import {
  createFailureAction,
  createSuccessAction,
  ListParams,
  Paginate,
  SerializableHttpErrorResponse,
} from '@angular-monorepo/shared/util-utils';

export const productActions = {
  // load: createAction('[Product/API] Load Products', props<{ params?: ListParams }>()),
  //
  // loadSuccess: createAction('[Product/API] Load Products Success', props<{ entities: Product[] }>()),
  //
  // loadFailure: createAction('[Product/API] Load Products Failure', props<{ error: SerializableHttpErrorResponse }>()),

  //
  // uploadImage: createAction('[Product/API] Upload Image', props<{ product: number; entity: Image }>()),
  //
  // uploadImageSuccess: createAction('[Product/API] Upload Image Success', props<{ product: number; entity: Image }>()),
  //
  // uploadImageFailure: createAction(
  //   '[Product/API] Upload Image Failure',
  //   props<{ error: SerializableHttpErrorResponse }>()
  // ),

  loadWithPagination: createAction('[Product/API] Load with Pagination Products', props<{ params?: ListParams }>()),

  loadWithPaginationSuccess: createAction(
    '[Product/API] Load with Pagination Products Success',
    props<{ paginateEntities: Paginate<Product[]> }>()
  ),

  loadWithPaginationFailure: createAction(
    '[Product/API] Load with Pagination Products Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),

  // add: createAction('[Product/API] Add Product', props<{ entity: Product }>()),

  // addSuccess: createAction('[Product/API] Add Product Success', props<{ entity: Product }>()),
  // addFailure: createAction('[Product/API] Add Product Failure', props<{ error: SerializableHttpErrorResponse }>()),

  update: createAction('[Product/API] Update Product', props<{ entity: Product }>()),
  updateSuccess: createSuccessAction('[Product/API] Update Product Success', props<{ entity: Product }>(), {
    title: 'Products updated',
  }),
  updateSuccessFinal: createSuccessAction('[Product/API] Update Product Success Final', props<{ entity: Product }>(), {
    title: 'Products updated',
  }),
  updateFailure: createFailureAction(
    '[Product/API] Update Product Failure',
    props<{ error: SerializableHttpErrorResponse }>(),
    {
      title: 'Failed to update products',
    }
  ),
  uploadImageFailure: createFailureAction(
    '[Product/API] Upload Image Failure',
    props<{ error: SerializableHttpErrorResponse; product_id: number }>()
  ),

  delete: createAction('[Product/API] Delete Product', props<{ id: number }>()),
  deleteSuccess: createSuccessAction('[Product/API] Delete Product Success', props<{ id: number }>(), {
    title: 'Product deleted successfully',
  }),
  deleteFailure: createFailureAction(
    '[Product/API] Delete Product Failure',
    props<{ error: SerializableHttpErrorResponse }>(),
    {
      title: 'Failed to delete product',
    }
  ),

  get: createAction('[Product/API] Get Product', props<{ id: number }>()),

  getSuccess: createAction('[Product/API] Get Product Success', props<{ entity: Product }>()),

  getFailure: createAction('[Product/API] Get Product Failure', props<{ error: SerializableHttpErrorResponse }>()),
  selectEntity: createAction('[Product/API] Select Product', props<{ id: string | number }>()),
};
