import { ChangeDetectionStrategy, Component, Inject, NgModule, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'components-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YesNoComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string; content: string }) {}

  ngOnInit(): void {}
}

@NgModule({
  declarations: [YesNoComponent],
  imports: [MatButtonModule, MatDialogModule],
})
export class YesNoModule {}
