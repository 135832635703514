import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormlyFieldCheckbox } from '@ngx-formly/material/checkbox';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';

@Component({
  selector: 'components-formly-checkbox-nullable',
  template: ` <mat-checkbox
    [id]="id"
    [formlyAttributes]="field"
    [checked]="value"
    [tabIndex]="props.tabindex"
    [indeterminate]="props.indeterminate && formControl.value === null"
    [color]="props.color"
    [labelPosition]="$any($any(props).align) || props.labelPosition"
    (click)="click()"
  >
    {{ props.label }}
    <span
      *ngIf="props.required && props.hideRequiredMarker !== true"
      aria-hidden="true"
      class="mat-form-field-required-marker"
      >*</span
    >
  </mat-checkbox>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } }],
})
export class FormlyCheckboxNullableComponent extends FormlyFieldCheckbox {
  click() {
    if (this.value == null) {
      this.value = true;
    } else if (this.value) {
      this.value = false;
    } else if (!this.value) {
      this.value = null;
    }
    this.formControl.markAsDirty();
  }
}
