import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestroyPopupComponent, DestroyPopupDirective } from './delete-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  declarations: [DestroyPopupComponent, DestroyPopupDirective],
  exports: [DestroyPopupDirective],
})
export class SharedFeatureDeletePopupModule {}
