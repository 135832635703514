import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { partnerActions } from '../+state/partner';
import { firstValueFrom, Observable } from 'rxjs';
import { ApiPartnerKey, Partner } from '../entities';
import * as partnerSelectors from '../+state/partner/partner.selectors';
import * as equal from 'fast-deep-equal/es6';

@Injectable({
  providedIn: 'root',
})
export class PartnerFacade {
  partner$ = this.store.select(partnerSelectors.selectPartner);
  loading$ = this.store.select(partnerSelectors.selectPartnerLoading);
  updated = false;
  temp: Partner = {} as Partner;
  selectApiKey$: Observable<ApiPartnerKey | undefined>;

  constructor(private store: Store) {
    this.selectApiKey$ = this.store.select(partnerSelectors.selectApiKey);
    this.partner$.subscribe((partner) => {
      this.updated = false;
      this.temp = JSON.parse(JSON.stringify(partner));
    });
  }

  async updateTemp(tempPartial: Partial<Partner>) {
    this.updated = false;
    const partner = await firstValueFrom(this.partner$);
    this.temp = {
      ...partner,
      ...this.temp,
      ...tempPartial,
    };
    if (!equal(this.temp, partner)) {
      this.updated = true;
    }
  }

  save() {
    this.store.dispatch(partnerActions.update({ entity: this.temp }));
  }

  getPartner(partner_id: number | undefined) {
    if (partner_id) {
      this.store.dispatch(partnerActions.get({ id: partner_id }));
    }
  }
  generateApiKey(expiry_date: string) {
    this.store.dispatch(partnerActions.generateApiKey({ expiry_date }));
  }

  removeApiKey() {
    this.store.dispatch(partnerActions.removeApiKey());
  }
  revokeApiKey() {
    this.store.dispatch(partnerActions.revokeOldApiKey());
  }
  redirectToStripe(url: string) {
    this.store.dispatch(partnerActions.redirectToStripe({ url }));
  }
}
