// import { FormlyExtension } from '@ngx-formly/core/lib/services/formly.config';
import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';

export const formlyAutoPrefixSuffixWrapperExtension: FormlyExtension = {
  onPopulate(field: FormlyFieldConfig) {
    if ((field?.props?.icon || field?.props?.units) && !field?.wrappers?.includes('prefix-suffix')) {
      field.wrappers = [...(field.wrappers || []), 'prefix-suffix'];
    }
  },
};
