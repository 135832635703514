import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of, timer } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import * as authActions from './auth.actions';
import { initAuth } from './auth.actions';
import { Location } from '@angular/common';
import { authSelectors } from './auth.selectors';
import { AuthService } from '../../infrastructure/auth.service';
import { SerializableHttpErrorResponse } from '@angular-monorepo/shared/util-utils';
import { Tokens } from '../../entities/user';
import { userActions } from '../user';

@Injectable()
export class AuthEffects {
  loginUrl = '/authentication/login';
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.login),
      concatLatestFrom(() => this.store.select(authSelectors.selectRedirectUrl)),
      exhaustMap(([action, redirectUrl]) =>
        this.authService.login(action.credentials).pipe(
          map((tokens: Tokens) => {
            if (redirectUrl) {
              this.router.navigateByUrl(redirectUrl);
            }
            return authActions.loginSuccess({ tokens });
          }),
          catchError((error: SerializableHttpErrorResponse) => of(authActions.loginFailure({ error })))
        )
      )
    );
  });

  newTokens$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.loginSuccess, authActions.refreshSuccess),
      mergeMap((action) => {
        this.authService.setTokens(action.tokens);
        return [
          authActions.setUser({
            user: this.jwtHelper.decodeToken(action.tokens.access),
          }),
          userActions.getSuccess({
            entity: this.jwtHelper.decodeToken(action.tokens.access),
          }),
          authActions.setNewRefreshDate({
            refreshExpirationDate: this.jwtHelper.getTokenExpirationDate(action.tokens.refresh)?.toJSON() || null,
          }),
        ];
      })
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.logout),
      // todo: use select method instead of pipe
      concatLatestFrom(() => this.store.select(authSelectors.selectTokens)),
      map(([_, tokens]) => {
        if (tokens) {
          this.authService.logout(tokens).subscribe();
        }
        return authActions.logoutSuccess();
      })
    );
  });

  removeTokens$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.logoutSuccess, authActions.refreshFailure, authActions.autoLogout),
      map(() => {
        this.authService.cleanStorage();
        return authActions.setNewRefreshDate({
          refreshExpirationDate: null,
        });
      })
    );
  });

  autoLogoutTrigger$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.setNewRefreshDate),
      switchMap((action) => {
        if (action.refreshExpirationDate == null) {
          return EMPTY;
        }
        return timer(new Date(action.refreshExpirationDate)).pipe(
          map(() => {
            return authActions.autoLogout();
          })
        );
      })
    );
  });

  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initAuth),
      map(() => {
        const tokens = this.authService.getTokensFromCache();
        if (!this.authService.areTokensFilled(tokens)) {
          return authActions.logoutSuccess();
        }
        try {
          if (this.jwtHelper.isTokenExpired(tokens.refresh)) {
            return authActions.autoLogout();
          }
        } catch (Error) {
          // the registered tokens are not in a correct JWT format
          return authActions.logoutSuccess();
        }
        return authActions.loginSuccess({ tokens });
      })
    );
  });
  redirectLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.logoutSuccess, authActions.refreshFailure, authActions.autoLogout),
        map(() => {
          const is_authentication_module = this.location.path().split('/')[1] === 'authentication';
          // KNOWN ISSUE: if executed by app init, this.router.url will return "/"
          // But this is ok, because the app init will have set redirectUrl beforehand
          if (!is_authentication_module) {
            this.router.navigateByUrl(this.loginUrl); // navigate to current url to force guards reload)
          }
        })
      );
    },
    { dispatch: false }
  );

  register$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.register),
      switchMap((action) => {
        return this.authService.register(action.id, action.data).pipe(
          map(() => authActions.registerSuccess()),
          catchError((error: SerializableHttpErrorResponse) => {
            return of(authActions.registerFailure({ error }));
          })
        );
      })
    );
  });
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private store: Store,
    private location: Location
  ) {}
}
