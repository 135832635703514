import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { NotificationUiFacade } from '@angular-monorepo/notifications/domain';
import { productActions } from '../product';
import { productVariantActions } from './product-variant.actions';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductVariantStoreEffects {
  loadFromProductGet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(productActions.getSuccess, productActions.updateSuccess),
      map(({ entity }) => {
        return productVariantActions.loadSuccess({ entities: entity.variants ?? [] });
      })
    );
  });

  constructor(
    protected actions$: Actions,
    // protected entityService: ProductVariantServive,
    protected store: Store,
    private notification: NotificationUiFacade
  ) {}
}
