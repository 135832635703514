import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'components-formly-field-error',
  templateUrl: './formly-field-error.component.html',
  styleUrls: ['./formly-field-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/*implements OnInit OnDestroy*/
export class FormlyFieldErrorComponent extends FieldType<FormlyFieldConfig> {
  // destroy$ = new Subject<void>();
  // constructor() {
  //   super();
  // }
  // ngOnInit() {
  //   super.ngOnInit();
  //   merge(
  //     this.form.valueChanges,
  //     this.field?.options?.parentForm?.ngSubmit ?? of(null)
  //   )
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(() => {
  //       // this.field?.formControl?.setErrors(null);
  //     });
  // }
  // ngOnDestroy() {
  //   super.ngOnDestroy();
  //   this.destroy$.next();
  //   this.destroy$.complete();
  // }
}
