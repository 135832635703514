import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ENVIRONMENT_CONFIG,
  EnvironmentConfig,
  FileDownloader,
  GenericService,
} from '@angular-monorepo/shared/util-utils';
import { Importation } from '../entities/importation';

@Injectable({
  providedIn: 'root',
})
export class ImportationServive extends GenericService<Importation> {
  protected constructor(
    protected http: HttpClient,
    private fileDownloader: FileDownloader,
    @Inject(ENVIRONMENT_CONFIG) protected environment: EnvironmentConfig
  ) {
    super(http, environment);
    this.setUrl('/partner/importation/');
  }

  create(entity: Importation, url: string = this.url) {
    const formData = new FormData();
    formData.append('type', entity.type);
    formData.append('imported_file', entity.imported_file);
    formData.append('integration_specification_name', entity.integration_specification_name);
    return this.http.post<Importation>(url, formData);
  }

  downloadOriginalFile(entity: Importation) {
    this.fileDownloader.get(this.computeUrl(`/partner/importation/${entity.id}/download_imported_file`)).subscribe();
  }

  downloadCommentFile(entity: Importation) {
    this.fileDownloader.get(this.computeUrl(`/partner/importation/${entity.id}/download_file_comment`)).subscribe();
  }

  downloadSuccessFile(entity: Importation) {
    this.fileDownloader.get(this.computeUrl(`/partner/importation/${entity.id}/download_file_success`)).subscribe();
  }

  downloadErrorFile(entity: Importation) {
    this.fileDownloader.get(this.computeUrl(`/partner/importation/${entity.id}/download_file_error`)).subscribe();
  }
}
