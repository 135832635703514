import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef, NgModule } from '@angular/core';
import { FieldWrapper, FormlyExtension, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MatTooltipModule } from '@angular/material/tooltip';

export const autoTooltipWrapperExtension: FormlyExtension = {
  prePopulate(field: FormlyFieldConfig) {
    if (field.props?.tooltip) {
      if (!field.wrappers?.includes('tooltip')) {
        field.wrappers = ['tooltip', ...(field.wrappers ?? [])];
      }
    }
  },
};

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip-wrapper.component.html',
  styleUrls: ['./tooltip-wrapper.component.css'],
})
export class TooltipWrapperComponent extends FieldWrapper {}

@NgModule({
  declarations: [TooltipWrapperComponent],
  imports: [
    FormlyModule.forChild({
      extensions: [
        {
          name: 'tooltip',
          extension: autoTooltipWrapperExtension,
        },
      ],
      wrappers: [
        {
          name: 'tooltip',
          component: TooltipWrapperComponent,
        },
      ],
    }),
    MatTooltipModule,
  ],
})
export class FormlyTooltipWrapperModule {}
