

<div class="fields-wrapper">
  <button class="add" mat-raised-button color="primary" (click)="add(undefined, undefined, {markAsDirty: true})">
    Add package
    <mat-icon>add</mat-icon>
  </button>
  <mat-card class="mat-elevation-z4 card-field-wrapper" *ngFor="let f of field?.fieldGroup; let i = index">
    <mat-card-header>
      <mat-card-title >{{model[i]?.label ? model[i]?.label : 'Package #' + (i + 1)}}</mat-card-title>
      <button mat-raised-button color="warn" (click)="remove(i)">
        Delete
        <mat-icon>delete</mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content class="">
        <formly-field [field]="f"></formly-field>
    </mat-card-content>

  </mat-card>



  <ng-content></ng-content>


</div>
<components-formly-field-error *ngIf="showError"
                               [field]="field">
</components-formly-field-error>
