import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { attributeActions, attributesSelectors } from '../+state/attribute';
import { Store } from '@ngrx/store';
import { Attribute } from '../entities/attribute';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { ListParams } from '@angular-monorepo/shared/util-utils';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({ providedIn: 'root' })
export class AttributeFacade {
  errors$ = this.store.select(attributesSelectors.selectErrors);
  attributeAll$ = this.store.select(attributesSelectors.selectAttributesAll);
  attributeEntities$ = this.store.select(attributesSelectors.selectAttributesEntities);
  loading$ = this.store.select(attributesSelectors.selectAttributesLoading);
  attributesByCode$ = this.store.select(attributesSelectors.selectAttributesEntitiesByCode);
  openAttributeDialog$ = new EventEmitter<{
    component: ComponentType<unknown> | TemplateRef<unknown>;
    attribute: Attribute | null;
  }>();
  updateOrAddSuccess$ = this.actions.pipe(ofType(attributeActions.updateSuccess, attributeActions.addSuccess));

  delete(attribute: Attribute) {
    this.store.dispatch(attributeActions.delete(attribute));
  }
  openAttributeDialog<T>(component: ComponentType<T> | TemplateRef<T>, attribute: Attribute | null) {
    this.openAttributeDialog$.next({ component, attribute });
  }

  loadAll(params?: ListParams) {
    this.store.dispatch(attributeActions.load({ params }));
  }

  createAttribute(entity: Attribute) {
    this.store.dispatch(attributeActions.add({ entity }));
  }
  updateAttribute(entity: Attribute) {
    this.store.dispatch(attributeActions.update({ entity }));
  }

  constructor(private store: Store, private dialog: MatDialog, private actions: Actions) {
    this.openAttributeDialog$.subscribe(({ component, attribute }) => {
      this.dialog.open(component, { data: attribute });
    });
  }
}
