import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { productActions } from './product.actions';
import { Injectable } from '@angular/core';
import { ProductServive } from '../../infrastructure/product.service';
import { SerializableHttpErrorResponse } from '@angular-monorepo/shared/util-utils';
import { userSelectors } from '@angular-monorepo/authentication/domain';
import { Product } from '../../entities';

@Injectable()
export class ProductStoreEffects {
  loadWithPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(productActions.loadWithPagination),
      concatLatestFrom(() => this.store.select(userSelectors.selectUser).pipe(filter((user) => !!user?.partner_id))),
      concatMap(([action, user]) => {
        const params = {
          ...action.params,
          ...(user?.partner_id ? { children__stockrecords__partner: user.partner_id } : {}),
        };
        return this.entityService.listWithPagination(params).pipe(
          map((paginateEntities) => productActions.loadWithPaginationSuccess({ paginateEntities: paginateEntities })),
          catchError((error: SerializableHttpErrorResponse) => of(productActions.loadWithPaginationFailure({ error })))
        );
      })
    );
  });

  // upload$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(productActions.uploadImage),
  //     // concatLatestFrom(() => this.store.select(userSelectors.selectUser).pipe(filter((user) => !!user?.partner_id))),
  //     concatMap(({ entity, product }) => {
  //       return this.entityService.uploadImage();
  //     })
  //   );
  // });

  // loadWithPagination$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(productActions.loadWithPagination),
  //     exhaustMap((action) =>
  //       this.entityService.listWithPagination(action.params).pipe(
  //         map((paginateEntities) => productActions.loadWithPaginationSuccess({ paginateEntities })),
  //         catchError((error: SerializableHttpErrorResponse) => of(productActions.loadWithPaginationFailure({ error })))
  //       )
  //     )
  //   );
  // });

  // add$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(productActions.add),
  //     mergeMap((action) =>
  //       this.entityService.create(action.entity).pipe(
  //         map((entity) => productActions.addSuccess({ entity })),
  //         catchError((error: SerializableHttpErrorResponse) => of(productActions.addFailure({ error })))
  //       )
  //     )
  //   );
  // });

  reloadProductAfterUploadImageFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(productActions.uploadImageFailure),
      switchMap(({ product_id }) => of(productActions.get({ id: product_id })))
    );
  });

  update$ = createEffect(() => {
    // @ts-ignore
    // @ts-ignore
    return this.actions$.pipe(
      ofType(productActions.update),
      concatMap((action) =>
        this.entityService.updateOrCreate(action.entity).pipe(
          // upload images using action.entity instead of the result from updateOrCreate, because frontToBack removes the images from the entity
          switchMap((entity) =>
            // upload images
            // third argument is if we need to get the updated entity from the server
            // we actually need it to update the images, except if the entity is created (it has an id)
            // in which case the user is redirected to the product page and we want to avoid an useless GET request
            this.entityService.uploadImages(action.entity, entity, action.entity.id != null).pipe(
              map((entity: Product) => productActions.updateSuccess({ entity })),
              catchError((error: SerializableHttpErrorResponse) => [
                // if we couldn't upload the images, we still want to update the product
                // so we can get the new images on the model
                productActions.updateSuccess({ entity }),
                productActions.uploadImageFailure({ error, product_id: entity.id }),
              ])
            )
          ),

          catchError((error: SerializableHttpErrorResponse) => of(productActions.updateFailure({ error })))
        )
      )
    );
  });

  delete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(productActions.delete),
      mergeMap((action) =>
        this.entityService.delete(action.id).pipe(
          map(() => productActions.deleteSuccess({ id: action.id })),
          catchError((error: SerializableHttpErrorResponse) => of(productActions.deleteFailure({ error })))
        )
      )
    );
  });

  get$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(productActions.get),
      mergeMap((action) =>
        this.entityService.get(action.id).pipe(
          map((entity) => productActions.getSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(productActions.getFailure({ error })))
        )
      )
    );
  });

  constructor(protected actions$: Actions, protected entityService: ProductServive, protected store: Store) {}
}
