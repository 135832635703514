import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ExportationStoreEffects } from './exportation.effects';
import { StoreModule } from '@ngrx/store';
import { exportationFeatureKey, exportationReducer } from './exportation.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(exportationFeatureKey, exportationReducer),
    EffectsModule.forFeature([ExportationStoreEffects]),
  ],
})
export class ExportationStoreModule {}
