import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationFacade } from '@angular-monorepo/authentication/domain';
import { AutofillFormErrors, BuildForm, defaultShowError, HandleFormErrors } from '@angular-monorepo/shared/util-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType, FormlyFormOptions } from '@ngx-formly/core';

export type LoginForm = {
  email: string;
  password: string;
};

@UntilDestroy()
@Component({
  selector: 'authentication-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, AutofillFormErrors {
  form = new UntypedFormGroup({});
  options = {
    showError: defaultShowError,
  } as FormlyFormOptions;
  model: LoginForm = {} as unknown as LoginForm;
  forgotPasswordUrl = this.authenticationFacade.forgotPasswordUrl;
  isLogged$ = this.authenticationFacade.isLogged$;

  errors$ = this.authenticationFacade.loginError$;
  isLoading$ = this.authenticationFacade.isLoading$;
  fields = BuildForm([
    {
      key: 'email',
      type: 'input',
      className: 'py-10',
      props: {
        label: 'Email address',
        placeholder: 'Enter email',
        required: true,
      },
    },
    {
      key: 'password',
      type: 'input',
      props: {
        label: 'Password',
        required: true,
        type: 'password',
      },
    },
  ]);

  constructor(private authenticationFacade: AuthenticationFacade, private route: Router) {}

  ngOnInit() {
    this.isLogged$.pipe(untilDestroyed(this)).subscribe((islogged) => {
      if (islogged) {
        this.route.navigate(['/']);
      }
    });
    this.load();
    HandleFormErrors(this);
  }

  load(): void {
    // this.authenticationFacade.load();
  }

  onSubmit(model: LoginForm) {
    this.form.markAsPristine();
    this.authenticationFacade.login({
      email: model.email,
      password: model.password,
    });
  }
}
