import { Action, createReducer, on } from '@ngrx/store';
import { partnerActions } from './partner.actions';
import { ApiPartnerKey, Partner } from '../../entities/partner';

export const partnerFeatureKey = 'partner';

export interface PartnerState {
  loading: boolean;
  partner: Partner;
  apiKey?: ApiPartnerKey;
  oldApiKey?: ApiPartnerKey;
}

const initialState: PartnerState = {
  loading: false,
  partner: {
    cgv: '',
    manual_validation: true,
    maximum_shipping_delay_dommarket_warehouse: null as unknown as number,
    minimum_shipping_delay_dommarket_warehouse: null as unknown as number,
  } as Partner,
  apiKey: undefined,
  oldApiKey: undefined,
};

const entitiesReducer = createReducer(
  initialState,
  // begging action
  on(
    partnerActions.update,
    partnerActions.get,
    partnerActions.generateApiKey,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  // error action
  on(
    partnerActions.getFailure,
    partnerActions.updateFailure,
    partnerActions.generateApiKeyFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
    })
  ),

  on(partnerActions.getSuccess, (state, { entity }) => {
    return {
      ...state,
      partner: { ...entity },
      loading: false,
    };
  }),
  on(partnerActions.updateSuccess, (state, { entity }) => {
    return {
      ...state,
      partner: { ...entity },
      loading: false,
    };
  }),

  on(partnerActions.generateApiKeySuccess, (state, { object }) => {
    return {
      ...state,
      apiKey: { ...object },
      oldApiKey: state?.partner?.api_key
        ? { ...state.partner.api_key }
        : undefined,
      partner: {
        ...state.partner,
        api_key: { ...object, key: '' },
      },

      loading: false,
    };
  }),
  on(partnerActions.removeApiKey, (state) => {
    return {
      ...state,
      apiKey: undefined,
      loading: false,
    };
  }),
  on(partnerActions.revokeOldApiKeySuccess, (state) => {
    return {
      ...state,
      oldApiKey: undefined,
      loading: false,
    };
  })
);

export function partnerReducer(
  state: PartnerState = initialState,
  action: Action
): PartnerState {
  return entitiesReducer(state, action);
}
