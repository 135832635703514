import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, Observable } from 'rxjs';
import { NotificationUiFacade } from '../application/notification-ui.facade';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Injectable()
export class InterceptorOutsideNgrx implements HttpInterceptor {
  constructor(private facade: NotificationUiFacade, private _notifications: NotificationsService) {}

  intercept(originalRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const showError = originalRequest.headers.has('_internal_showError');
    if (showError) {
      const modifiedRequest = originalRequest.clone({
        headers: originalRequest.headers.delete('_internal_showError').delete('skipSuccess'),
      });
      return next.handle(modifiedRequest).pipe(
        catchError((err: HttpErrorResponse) => {
          this._notifications.create(
            'Unexpected error',
            err.error instanceof Blob ? err.message : undefined,
            NotificationType.Error,
            {},
            {
              json_error: err?.error?.detail,
            }
          );
          return EMPTY;
        })
      );
    } else {
      return next.handle(originalRequest);
    }
  }
}
