import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'components-formly-rich-text-editor',
  templateUrl: './formly-rich-text-editor.component.html',
  styleUrls: ['./formly-rich-text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyRichTextEditorComponent extends FieldType<FieldTypeConfig> {}
