import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { AppCoreModule } from './app-core.module';
import { AuthenticationFeatureAuthenticationModule } from '@angular-monorepo/authentication/feature-authentication';
import { NotificationsFeatureNotificationsModule } from '@angular-monorepo/notifications/feature-notifications';
import { SharedFeatureDeletePopupModule } from '@angular-monorepo/shared/feature-delete-popup';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '@angular-monorepo/shared/util-utils';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxJsonViewerModule } from '@angular-monorepo/shared/ui-components';

@NgModule({
  declarations: [AppComponent],

  imports: [
    CommonModule,
    SharedModule,
    AppCoreModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    AppRoutingModule,
    AuthenticationFeatureAuthenticationModule,
    NotificationsFeatureNotificationsModule,
    SharedFeatureDeletePopupModule,
    MatButtonModule,
    MatProgressBarModule,
    MatCardModule,
    CdkScrollableModule,
    // DropFileModule,
    NgxJsonViewerModule,

    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    SimpleNotificationsModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
