import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'components-formly-accordion-group',
  templateUrl: './formly-accordion-group.component.html',
  styleUrls: ['./formly-accordion-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyAccordionGroupComponent extends FieldType {
  checkError(field: FormlyFieldConfig): boolean {
    if (field?.formControl != this.field.formControl) {
      return field?.formControl?.invalid ?? false;
    }
    return field?.fieldGroup?.some((f) => this.checkError(f)) ?? false;
  }
}
