import { createAction, props } from '@ngrx/store';
import { ListParams, Paginate, SerializableHttpErrorResponse } from '@angular-monorepo/shared/util-utils';
import { ProductVariant } from '../../entities/product';

export const productVariantActions = {
  load: createAction('[ProductVariant/API] Load ProductVariants', props<{ params?: ListParams }>()),

  loadSuccess: createAction(
    '[ProductVariant/API] Load ProductVariants Success',
    props<{ entities: ProductVariant[] }>()
  ),

  loadFailure: createAction(
    '[ProductVariant/API] Load ProductVariants Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),

  loadWithPagination: createAction(
    '[ProductVariant/API] Load with Pagination ProductVariants',
    props<{ params?: ListParams }>()
  ),

  loadWithPaginationSuccess: createAction(
    '[ProductVariant/API] Load with Pagination ProductVariants Success',
    props<{ paginateEntities: Paginate<ProductVariant[]> }>()
  ),

  loadWithPaginationFailure: createAction(
    '[ProductVariant/API] Load with Pagination ProductVariants Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),

  add: createAction('[ProductVariant/API] Add ProductVariant', props<{ entity: ProductVariant }>()),

  addSuccess: createAction('[ProductVariant/API] Add ProductVariant Success', props<{ entity: ProductVariant }>()),
  addFailure: createAction(
    '[ProductVariant/API] Add ProductVariant Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),

  update: createAction('[ProductVariant/API] Update ProductVariant', props<{ entity: ProductVariant }>()),

  updateSuccess: createAction(
    '[ProductVariant/API] Update ProductVariant Success',
    props<{ entity: ProductVariant }>()
  ),

  updateFailure: createAction(
    '[ProductVariant/API] Update ProductVariant Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),

  delete: createAction('[ProductVariant/API] Delete ProductVariant', props<{ id: number }>()),

  deleteSuccess: createAction('[ProductVariant/API] Delete ProductVariant Success', props<{ id: number }>()),

  deleteFailure: createAction(
    '[ProductVariant/API] Delete ProductVariant Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),

  get: createAction('[ProductVariant/API] Get ProductVariant', props<{ id: number }>()),

  getSuccess: createAction('[ProductVariant/API] Get ProductVariant Success', props<{ entity: ProductVariant }>()),

  getFailure: createAction(
    '[ProductVariant/API] Get ProductVariant Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),
  selectEntity: createAction('[ProductVariant/API] Select ProductVariant', props<{ id: string | number }>()),
};
