import { ApiPartnerKey, Partner } from '../../entities/';
import { createAction, props } from '@ngrx/store';
import {
  createFailureAction,
  createSuccessAction,
  SerializableHttpErrorResponse,
} from '@angular-monorepo/shared/util-utils';

export const partnerActions = {
  update: createAction('[Partner/API] Update Partner', props<{ entity: Partner }>()),
  updateSuccess: createSuccessAction('[Partner/API] Update Partner Success', props<{ entity: Partner }>(), {
    title: 'Update success',
  }),
  updateFailure: createFailureAction(
    '[Partner/API] Update Partner Failure',
    props<{ error: SerializableHttpErrorResponse }>(),
    {
      title: 'Update failure',
    }
  ),

  get: createAction('[Partner/API] Get Partner', props<{ id: number }>()),

  getSuccess: createAction('[Partner/API] Get Partner Success', props<{ entity: Partner }>()),

  getFailure: createAction('[Partner/API] Get Partner Failure', props<{ error: SerializableHttpErrorResponse }>()),

  generateApiKey: createAction('[Partner/API] generateApiKey Partner', props<{ expiry_date: string }>()),
  generateApiKeySuccess: createSuccessAction(
    '[Partner/API] generateApiKey Partner Success',
    props<{ object: ApiPartnerKey }>(),
    {
      title: 'API key generated',
    }
  ),
  generateApiKeyFailure: createFailureAction(
    '[Partner/API] generateApiKey Partner Failure',
    props<{ error: SerializableHttpErrorResponse }>(),
    {
      title: 'Failed to generate API key',
    }
  ),

  removeApiKey: createAction('[Partner/API] remove Partner APIKey'),

  revokeOldApiKey: createAction('[Partner/API] revokeOldApiKey Partner'),
  revokeOldApiKeySuccess: createSuccessAction('[Partner/API] revokeOldApiKey Partner Success', undefined, {
    title: 'Old API key revoked',
  }),
  revokeOldApiKeyFailure: createFailureAction(
    '[Partner/API] revokeOldApiKey Partner Failure',
    props<{ error: SerializableHttpErrorResponse }>(),
    { title: 'Failed to revoke old API key' }
  ),
  redirectToStripe: createAction('[Partner/API] redirect to stripe', props<{ url: string }>()),
};
