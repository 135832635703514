import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { EntityState } from '@ngrx/entity/src/models';
import { Action, createReducer, on } from '@ngrx/store';
import { productActions } from './product.actions';
import { Product } from '../../entities/product';
import { SerializableHttpErrorResponse, StatePaginate } from '@angular-monorepo/shared/util-utils';

export const productFeatureKey = 'product';

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>();

export interface ProductState extends EntityState<Product>, StatePaginate {
  errors?: SerializableHttpErrorResponse;
}

const initialState: ProductState = adapter.getInitialState({
  loading: false,
  count: 0,
});

const entitiesReducer = createReducer(
  initialState,
  // begging action
  on(
    // productActions.load,
    productActions.loadWithPagination,
    // productActions.add,
    productActions.update,
    productActions.delete,
    productActions.get,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  // error action
  on(
    productActions.getFailure,
    productActions.updateFailure,
    // productActions.loadFailure,
    productActions.loadWithPaginationFailure,
    productActions.deleteFailure,
    (state, { error }) => ({
      ...state,
      errors: error,
      loading: false,
    })
  ),

  // on(productActions.loadSuccess, (state, { entities }) => {
  //   const products = entities.map((product) => {
  //     const { ...rest } = product;
  //     return {
  //       ...rest,
  //       variants: [],
  //       // product: product,
  //     } as Product;
  //   });
  //   return adapter.setAll(products, {
  //     ...state,
  //     loading: false,
  //   });
  // }),
  on(productActions.loadWithPaginationSuccess, (state, { paginateEntities }) => {
    return adapter.setAll(paginateEntities.results, {
      ...state,
      count: paginateEntities.count,
      next: paginateEntities.next,
      previous: paginateEntities.previous,
      loading: false,
    });
  }),
  // on(productActions.addSuccess, (state, { entity }) => {
  //   return adapter.addOne(entity, {
  //     ...state,
  //     loading: false,
  //   });
  // }),
  on(productActions.getSuccess, (state, { entity }) => {
    return adapter.upsertOne(entity, {
      ...state,
      loading: false,
    });
  }),
  on(productActions.updateSuccess, (state, { entity }) => {
    return adapter.upsertOne(entity, {
      ...state,
      loading: false,
    });
  }),
  on(productActions.updateSuccessFinal, (state, { entity }) => {
    return adapter.upsertOne(entity, {
      ...state,
      loading: false,
    });
  }),
  on(productActions.deleteSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      loading: false,
    });
  })
);

export function productReducer(state: ProductState = initialState, action: Action): ProductState {
  return entitiesReducer(state, action);
}
