<div>
  <h3 *ngIf="props.headerText">{{ props.headerText }}</h3>
  <div class="field" *ngFor="let field of field.fieldGroup; let i = index;">
    <formly-field [field]="field"></formly-field>
    <button mat-icon-button color="warn"
            [attr.aria-label]="props.removeText || ''"
            *ngIf="i >= (props.minLength || 0)"
            (click)="remove(i)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <components-formly-field-error [field]="field"></components-formly-field-error>
  <div>
    <button #addButton class="add-button" mat-stroked-button color="primary"
            (click)="add()">{{ props.addText }}</button>
  </div>
</div>

