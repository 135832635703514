import { EnvironmentConfig } from '@angular-monorepo/shared/util-utils';
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
export const environment: EnvironmentConfig = {
  appVersion: '0.13.0',
  production: true,
  shopName: 'Dommarket',
  api: {
    prefix: 'v1',
    baseDomain: 'dommarket.fr',
    suffixDomain: `/api/`,
    scheme: 'https',
  },
  sentry: {
    DSN: 'https://bc9a1ebf871ee086707ead7df8c41401@o4506353910743040.ingest.sentry.io/4506353946263552',
    env: 'production',
    release: '#RENTRY_RELEASE',
  },
  localStorageKeys: {
    accessToken: 'access_token',
    refreshToken: 'refresh_token',
  },
};
