import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { JWT_OPTIONS, JwtConfig, JwtModule } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { ENVIRONMENT_CONFIG, EnvironmentConfig } from '@angular-monorepo/shared/util-utils';
import { HttpErrorInterceptor, RefreshTokenInterceptor } from './interceptors';
import { authSelectors } from './+state/auth';
import { initAuth } from './+state/auth/auth.actions';

export function jwtOptionsFactory(store: Store, environment: EnvironmentConfig): JwtConfig {
  return {
    tokenGetter: () => {
      return firstValueFrom(store.select(authSelectors.selectAccessToken));
    },
    allowedDomains: [environment.api.baseDomain],
    headerName: 'Authorization',
    authScheme: 'Bearer ',
    skipWhenExpired: true,
  };
}

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
  ],
})
class RefreshTokenModule {}

@NgModule({
  imports: [
    RefreshTokenModule, // needs to be imported before JwtModule for correct interceptor ordering.
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Store, ENVIRONMENT_CONFIG],
      },
    }),
    HttpClientModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store) => {
        return () => {
          store.dispatch(initAuth());
        };
      },
      multi: true,
      deps: [Store],
    },
  ],
})
export class JwtConfigModule {
  constructor(@Optional() @SkipSelf() parentModule: JwtConfigModule) {
    if (parentModule) {
      throw new Error(
        "AppAuthConfigModule is already loaded. It should only be imported in your application's main module."
      );
    }
  }
}
