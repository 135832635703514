import { Attribute } from '../../entities/attribute';
import { createAction, props } from '@ngrx/store';
import {
  createFailureAction,
  createSuccessAction,
  ListParams,
  ListParamsLimitOffset,
  Paginate,
  SerializableHttpErrorResponse,
} from '@angular-monorepo/shared/util-utils';

export const attributeActions = {
  load: createAction('[Attributes/API] Load Attributes', props<{ params?: ListParams }>()),
  loadSuccess: createAction('[Attributes/API] Load Attributes Success', props<{ entities: Attribute[] }>()),
  loadFailure: createAction(
    '[Attributes/API] Load Attributes Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),

  loadWithPagination: createAction(
    '[Attributes/API] Load with Pagination Attributes',
    props<{ params?: ListParamsLimitOffset }>()
  ),
  loadWithPaginationSuccess: createAction(
    '[Attributes/API] Load with Pagination Attributes Success',
    props<{ paginateEntities: Paginate<Attribute[]> }>()
  ),
  loadWithPaginationFailure: createAction(
    '[Attributes/API] Load with Pagination Attributes Failure',
    props<{ error: SerializableHttpErrorResponse }>()
  ),

  add: createAction('[Attributes/API] Add Attribute', props<{ entity: Attribute }>()),
  addSuccess: createSuccessAction('[Attributes/API] Add Attribute Success', props<{ entity: Attribute }>(), {
    title: 'Attribute created successfully',
  }),
  addFailure: createFailureAction(
    '[Attributes/API] Add Attribute Failure',
    props<{ error: SerializableHttpErrorResponse }>(),
    {
      content: 'Failed to create attribute',
    }
  ),

  update: createAction('[Attributes/API] Update Attribute', props<{ entity: Attribute }>()),
  updateSuccess: createSuccessAction('[Attributes/API] Update Attribute Success', props<{ entity: Attribute }>(), {
    title: 'Attribute updated successfully',
  }),
  updateFailure: createFailureAction(
    '[Attributes/API] Update Attribute Failure',
    props<{ error: SerializableHttpErrorResponse }>(),
    {
      content: 'Failed to update attribute',
    }
  ),

  delete: createAction('[Attributes/API] Delete Attribute', props<{ id: number }>()),
  deleteSuccess: createSuccessAction('[Attributes/API] Delete Attribute Success', props<{ id: number }>(), {
    title: 'Attribute deleted successfully',
  }),
  deleteFailure: createFailureAction(
    '[Attributes/API] Delete Attribute Failure',
    props<{ error: SerializableHttpErrorResponse }>(),
    {
      title: 'Failed to delete attribute',
    }
  ),

  get: createAction('[Attributes/API] Get Attribute', props<{ id: number }>()),
  getSuccess: createAction('[Attributes/API] Get Attribute Success', props<{ entity: Attribute }>()),
  getFailure: createAction('[Attributes/API] Get Attribute Failure', props<{ error: SerializableHttpErrorResponse }>()),

  selectEntity: createAction('[Attributes/API] Select Attribute', props<{ id: string | number }>()),
};
