import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { attributeActions } from './attributeActions';
import { Injectable } from '@angular/core';
import { AttributesServive } from '../../infrastructure/attributes.service';
import { SerializableHttpErrorResponse } from '@angular-monorepo/shared/util-utils';
import { userSelectors } from '@angular-monorepo/authentication/domain';

@Injectable()
export class AttributeStoreEffects {
  load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(attributeActions.load),
      concatLatestFrom(() => this.store.select(userSelectors.selectUser).pipe(filter((user) => !!user?.partner_id))),
      exhaustMap(([action, user]) => {
        return this.entityService
          .list({
            ...action.params,
            ...(user?.partner_id ? { partner: user.partner_id } : {}),
          })
          .pipe(
            map((entities) => attributeActions.loadSuccess({ entities })),
            catchError((error: SerializableHttpErrorResponse) => of(attributeActions.loadFailure({ error })))
          );
      })
    );
  });

  loadWithPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(attributeActions.loadWithPagination),
      exhaustMap((action) => {
        return this.entityService.listWithPagination(action.params).pipe(
          map((paginateEntities) => attributeActions.loadWithPaginationSuccess({ paginateEntities })),
          catchError((error: SerializableHttpErrorResponse) =>
            of(attributeActions.loadWithPaginationFailure({ error }))
          )
        );
      })
    );
  });

  add$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(attributeActions.add),
      mergeMap((action) =>
        this.entityService.create(action.entity).pipe(
          map((entity) => attributeActions.addSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(attributeActions.addFailure({ error })))
        )
      )
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(attributeActions.update),
      concatMap((action) =>
        this.entityService.update(action.entity).pipe(
          map((entity) => attributeActions.updateSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(attributeActions.updateFailure({ error })))
        )
      )
    );
  });

  delete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(attributeActions.delete),
      mergeMap((action) =>
        this.entityService.delete(action.id).pipe(
          map(() => attributeActions.deleteSuccess({ id: action.id })),
          catchError((error: SerializableHttpErrorResponse) => of(attributeActions.deleteFailure({ error })))
        )
      )
    );
  });

  get$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(attributeActions.get),
      switchMap((action) =>
        this.entityService.get(action.id).pipe(
          map((entity) => attributeActions.getSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(attributeActions.getFailure({ error })))
        )
      )
    );
  });
  constructor(protected actions$: Actions, protected entityService: AttributesServive, protected store: Store) {}
}
