import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as authActions from '../+state/auth/auth.actions';
import { Credentials } from '../entities/user';
import { authSelectors, selectIsLoading, selectIsLogged, selectLoginError } from '../+state/auth';
import { ENVIRONMENT_CONFIG, EnvironmentConfig } from '@angular-monorepo/shared/util-utils';
import { selectPartnerId, selectUser } from '../+state/user/user.selectors';

@Injectable({ providedIn: 'root' })
export class AuthenticationFacade {
  forgotPasswordUrl = `${this.environment.api.scheme}://${this.environment.api.baseDomain}/password-reset/`;
  isLogged$ = this.store.select(selectIsLogged);
  loginError$ = this.store.select(selectLoginError);
  isLoading$ = this.store.select(selectIsLoading);
  user$ = this.store.select(selectUser);
  partnerId$ = this.store.select(selectPartnerId);
  constructor(private store: Store, @Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfig) {}

  login(credentials: Credentials) {
    this.store.dispatch(authActions.login({ credentials: { ...credentials } }));
  }

  logout() {
    this.store.dispatch(authActions.logout());
  }

  register(id: string, data: { password: string; email: string; token: string }) {
    this.store.dispatch(authActions.register({ id, data }));
  }
}
