import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map } from 'rxjs';
import * as countries from 'i18n-iso-countries';

@Injectable({ providedIn: 'root' })
export class CountryService {
  initialized$ = new BehaviorSubject(false);
  _initialized$ = this.initialized$.asObservable();
  lang = (navigator.language ?? 'en').split('-')[0];

  constructor() {
    import(`i18n-iso-countries/langs/${this.lang}.json`).then((module) => {
      // console.log('registering i18n-iso-countries locale :', module.locale);
      countries.registerLocale(module);
      this.initialized$.next(true);
    });
  }

  public getLanguages() {
    // wait for initialization
    return this._initialized$.pipe(
      filter((initialized) => initialized),
      map(() => {
        return countries.getNames(this.lang);
      })
    );
  }
}
