import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { userActions } from './user.actions';
import { Injectable } from '@angular/core';
import { UserServive } from '../../infrastructure/user.service';
import { SerializableHttpErrorResponse } from '@angular-monorepo/shared/util-utils';
import { authSelectors } from '../auth';
import { User } from '../../entities/user';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class UserStoreEffects {
  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.update),
      concatMap((action) =>
        this.entityService.update(action.entity).pipe(
          map((entity) => userActions.updateSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(userActions.updateFailure({ error })))
        )
      )
    );
  });

  get$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.get),
      switchMap((action) =>
        this.entityService.get(action.id).pipe(
          map((entity) => userActions.getSuccess({ entity })),
          catchError((error: SerializableHttpErrorResponse) => of(userActions.getFailure({ error })))
        )
      )
    );
  });

  updateEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.updateEmail),
      concatLatestFrom(() => this.store.select(authSelectors.selectUser)),
      switchMap(([action, user]) => {
        if (!user) {
          user = {} as User;
        }
        user = { ...user, email: action.email };

        return this.entityService.update(user).pipe(
          map((entity) => {
            this._notifications.success('Email Updated');
            return userActions.getSuccess({ entity });
          }),
          catchError((error: SerializableHttpErrorResponse) => of(userActions.getFailure({ error })))
        );
      })
    );
  });

  updatePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.updatePassword),
      concatLatestFrom(() => this.store.select(authSelectors.selectUser)),
      switchMap(([action, user]) => {
        return this.entityService.changePassword(user?.id, action).pipe(
          map((entity) => {
            this._notifications.success('Password Updated');
            return userActions.getSuccess({ entity });
          }),
          catchError((error: SerializableHttpErrorResponse) => of(userActions.getFailure({ error })))
        );
      })
    );
  });

  constructor(
    protected actions$: Actions,
    protected entityService: UserServive,
    protected store: Store,
    private _notifications: NotificationsService
  ) {}
}
