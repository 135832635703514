import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, productFeatureKey, ProductState } from './product.reducer';
import { productVariantSelectors } from '../product-variant';
import { Product } from '../../entities/product';
import { routerSelectors } from '@angular-monorepo/shared/util-utils';

const { selectIds, selectAll, selectEntities, selectTotal } = adapter.getSelectors();

const selectFeatureSelector = createFeatureSelector<ProductState>(productFeatureKey);

const selectState = createSelector(selectFeatureSelector, (state) => state);

const selectProductIds = createSelector(selectState, selectIds);
const selectProductAll = createSelector(selectState, selectAll);
const selectProductTotal = createSelector(selectState, selectTotal);

const selectProductEntities = createSelector(selectState, selectEntities);
const selectProductLoading = createSelector(selectState, (state) => state.loading);

const selectPagination = createSelector(selectFeatureSelector, (state) => ({
  next: state.next,
  previous: state.previous,
  count: state.count,
}));
const selectSelectedEntityId = createSelector(selectFeatureSelector, (state) => state.selectedEntityId);

const selectSelectedEntity = createSelector(
  selectProductEntities,
  selectSelectedEntityId,
  (entities, selectedEntityId) => {
    if (selectedEntityId) {
      return entities[selectedEntityId];
    } else {
      return undefined;
    }
  }
);

const selectRouteProductId = routerSelectors.selectRouteNestedParam('productId');
// createSelector(routerSelectors.selectRouteParam('productId'), (productId) => productId);

const selectRouteProduct = createSelector(
  selectProductEntities,
  productVariantSelectors.selectProductVariantEntities,
  selectRouteProductId,
  (productEntities, productVariantEntities, productId) => {
    // console.log('IN SELECTORS', { productVariantEntities, productId });
    if (isNaN(Number(productId))) {
      return undefined;
    }
    const product = productEntities[productId as string] as Product;
    if (!product) {
      return undefined;
    }
    return {
      ...product,
      variants: product.variant_ids.map(
        (productVariantId) => productVariantEntities[productVariantId] ?? ({} as Product)
      ),
    } as Product;
  }
);

export const productSelectors = {
  selectProductIds,
  selectProductAll,
  selectProductTotal,
  selectProductEntities,
  selectProductLoading,
  selectPagination,
  selectSelectedEntityId,
  selectSelectedEntity,

  selectRouteProductId,
  selectRouteProduct,
};
