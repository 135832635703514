import { NgModule } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { SharedModule } from '@angular-monorepo/shared/util-utils';
import { SharedUiComponentsModule } from '../../../shared-ui-components.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { CategoryAttributesSelectionComponent } from './category-attributes-selection/category-attributes-selection.component';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { FormlyProductCategorySelectionComponent } from './formly-product-category-selection.component';
import { FormlyFieldErrorModule } from '../formly-field-error/formly-field-error.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [FormlyProductCategorySelectionComponent, CategoryAttributesSelectionComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'product-categories',
          component: FormlyProductCategorySelectionComponent,
        },
      ],
    }),
    MatButtonModule,
    MatDialogModule,
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    FormlyFieldErrorModule,
    // SharedUiComponentsModule,
    // SharedUiComponentsModule,
  ],
})
export class FormlyProductCategorySelectionModule {}
