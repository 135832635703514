import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Attribute, AttributeFacade } from '@angular-monorepo/attributes/domain';
import { Category } from '@angular-monorepo/category/domain';
import { BehaviorSubject, map, withLatestFrom } from 'rxjs';

@Component({
  selector: 'components-ui-category-attributes-selection',
  templateUrl: './category-attributes-selection.component.html',
  styleUrls: ['./category-attributes-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryAttributesSelectionComponent implements OnInit {
  @Input() productAttributes = new Set<string>();

  @Output() attributeAdded = new EventEmitter<Attribute>();
  @Input()
  get category() {
    return this._category;
  }
  set category(category: Category) {
    this._category = category;
    this._category$.next(category);
  }
  // set productAttributes(productAttributes: Set<string>) {
  //   console.log('productAttributes input', productAttributes);
  //   this._productAttributes = productAttributes;
  //   this._productAttributesSet = new Set(this._productAttributes);
  //   this._productAttributes?.forEach?.((productAttribute) => {
  //     this._productAttributesSet.add(productAttribute);
  //   });
  // }
  // get productAttributes() {
  //   return this._productAttributes;
  // }

  attributeEntities$ = this.attributeFacade.attributeEntities$;
  attributes$ = this.attributeFacade.attributeAll$;
  _category$ = new BehaviorSubject<Category>(null as unknown as Category);
  _attributes$ = this._category$.asObservable().pipe(
    withLatestFrom(this.attributeEntities$),
    map(([category, attributes]) => {
      return (category?.attributes ?? []).map((attributeId) => attributes[attributeId]);
    })
  );
  _category!: Category;
  displayedColumns: string[] = ['name', 'code', 'type', 'add'];
  constructor(private attributeFacade: AttributeFacade) {}

  ngOnInit(): void {}

  addAttribute(attribute: Attribute) {
    this.attributeAdded.next(attribute);
  }
}
