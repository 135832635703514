import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SerializableHttpErrorResponse } from '@angular-monorepo/shared/util-utils';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  /**
   * Interceptor to cast all HttpErrorResponse to a serialisable type
   * to be able to add http errors to the ngrx store
   */

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) =>
        throwError({
          statusCode: err.status,
          statusText:
            err.status === 423
              ? "Protected resource can't be deleted"
              : err.statusText,
          error: err.error,
        } as SerializableHttpErrorResponse)
      )
    );
  }
}
