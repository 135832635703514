import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT_CONFIG, EnvironmentConfig, GenericService, ListParams } from '@angular-monorepo/shared/util-utils';
// import { Image, Product, ProductBack, ProductVariant, ProductVariantMeasures } from '../entities/product';
import type {
  AttributeValue,
  Image,
  Product,
  ProductBack,
  ProductVariant,
  ProductVariantBack,
  ProductVariantMeasures,
} from '../entities';
import { defaultIfEmpty, forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductServive extends GenericService<ProductBack, Product> {
  protected constructor(
    protected http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) protected environment: EnvironmentConfig
  ) {
    super(http, environment);
    this.setUrl('/catalogue/product/');
  }

  getDummyImage(image: Image) {
    const reuse_id = image.id ?? image.reuse_id ?? null;
    return {
      reuse_id,
    } as unknown as Image;
  }
  frontToBack(entity: Partial<Product>): ProductBack {
    const { categories_names, variants, brand, ...rest } = entity;
    return {
      ...rest,
      brand: brand || null,
      images: entity?.images?.map?.(this.getDummyImage) ?? [],
      is_public: true, // always send is_public to true for the parent product
      variants:
        variants?.map?.(
          (variant) =>
            ({
              ...variant,
              images: variant?.images?.map?.(this.getDummyImage) ?? [],
            } as unknown as ProductVariantBack)
        ) ?? [],
      attributes_declaration: entity.attributes?.map?.((attribute) => attribute.code) ?? [],
      attributes: entity.attributes?.filter?.((attribute) => attribute.value != null) ?? [],
    } as ProductBack;
  }

  backToFront(entity: ProductBack) {
    const { variants, ...rest } = entity;
    // the backend doesn't return the attributes that are defined only in the variants and not in the parent, but the UI needs it
    const parentAttributes =
      entity.attributes?.reduce?.((prev: any, curr) => {
        prev[curr.code] = {
          code: curr.code,
          value: curr.value,
        };
        return prev;
      }, {}) ?? {};
    const allVariantAttributeCodes = new Set(
      variants?.flatMap?.((variants) => variants.attributes)?.map?.((attribute) => attribute.code) ?? []
    );
    for (const variantAttributeCode of [...allVariantAttributeCodes.values()]) {
      if (!parentAttributes[variantAttributeCode]) {
        parentAttributes[variantAttributeCode] = {
          code: variantAttributeCode,
          value: null,
        };
      }
    }
    // console.log('parentAttributes', parentAttributes);
    return {
      ...rest,
      images: entity.images.map((image) => ({
        ...image,
        displayUrl: `${this.environment.api.scheme}://${this.environment.api.baseDomain}/media/${image.file}`,
      })),
      attributes: Object.values(parentAttributes),
      variants:
        variants?.map((variant) => ({
          ...variant,
          price: Number(variant.price),
          ecopart: Number(variant.ecopart),
          images: variant.images.map((image) => ({
            ...image,
            displayUrl: `${this.environment.api.scheme}://${this.environment.api.baseDomain}/media/${image.file}`,
          })),
          attributes: variant.attributes
            // filter out the attributes that are defined in the parent
            .map((attribute) => ({
              code: attribute.code,
              value: attribute.value,
            }))
            .filter((attribute) => parentAttributes[attribute.code]?.value == null) as unknown as AttributeValue[],
          measures: {
            ...variant.measures,
            item: {
              ...variant.measures.item,
              length: Number(variant.measures.item.length),
              width: Number(variant.measures.item.width),
              height: Number(variant.measures.item.height),
              weight: Number(variant.measures.item.weight),
            },
            packages$: variant.measures?.packages?.map((packageMeasure) => ({
              ...packageMeasure,
              length: Number(packageMeasure.length),
              width: Number(packageMeasure.width),
              height: Number(packageMeasure.height),
              weight: Number(packageMeasure.weight),
            })),
          } as unknown as ProductVariantMeasures,
        })) ?? ([] as ProductVariant[]),
      variant_ids: variants?.map((variant) => variant.id) ?? [],
    } as Product;
  }

  // we need the new id that we got afeter updating the product, but entity is not updated yet and maybe does not have id
  uploadImages(entity: Product, new_entity: Product, do_redirect = false) {
    // store to which variant ean belongs images
    const images_eans = new Map<Image, string>();
    for (const variant of entity.variants) {
      for (const image of variant.images) {
        images_eans.set(image, variant.ean);
      }
    }
    // do the same for product template
    for (const image of entity.images) {
      images_eans.set(image, '' + new_entity.id);
    }

    // filter images
    const images = [...(entity.images ?? []), ...entity.variants.flatMap((variant) => variant.images ?? [])].filter(
      (image) => !image.id
    );

    // create a mapping with variant ids and variant ean (because we don't know the variant id at product creation but we know the ean)
    const ean_to_id = {} as { [id: string]: number };
    for (const variant of new_entity.variants) {
      ean_to_id[variant.ean] = variant.id;
    }
    ean_to_id[new_entity.id] = new_entity.id;
    return forkJoin(
      images.map((image) =>
        this.uploadImage({
          ...image,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          product: ean_to_id[images_eans.get(image)!],
        })
      )
    ).pipe(
      defaultIfEmpty(null),
      switchMap((entity) => {
        if (do_redirect) return this.get(new_entity.id);
        return of(new_entity);
      })
    );
  }

  uploadImage(entity: Image) {
    const formData = new FormData();
    if (entity.caption) {
      formData.append('caption', entity.caption);
    }
    formData.append('product', '' + entity.product);

    formData.append('file', entity.file);

    return this.http.post(
      `${this.environment.api.scheme}://${this.environment.api.baseDomain}${this.environment.api.suffixDomain}${this.environment.api.prefix}/catalogue/product_image/`,
      formData
    );
  }

  create(entity: Partial<Product>, url: string = this.url) {
    return super.create(entity, url);
  }
}
