import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, attributesFeatureKey, AttributesState } from './attribute.reducer';
import { Attribute } from '../../entities/attribute';

const { selectIds, selectAll, selectEntities, selectTotal } = adapter.getSelectors();

export const selectFeatureSelector = createFeatureSelector<AttributesState>(attributesFeatureKey);

export const selectState = createSelector(selectFeatureSelector, (state) => state);

export const selectAttributesIds = createSelector(selectState, selectIds);
export const selectAttributesAll = createSelector(selectState, selectAll);
export const selectAttributesTotal = createSelector(selectState, selectTotal);

export const selectAttributesEntities = createSelector(selectState, selectEntities);
export const selectAttributesLoading = createSelector(selectState, (state) => state.loading);
export const selectErrors = createSelector(selectState, (state) => state.errors);

export const selectPagination = createSelector(selectFeatureSelector, (state) => ({
  next: state.next,
  previous: state.previous,
  count: state.count,
}));
export const selectSelectedEntityId = createSelector(selectFeatureSelector, (state) => state.selectedEntityId);

export const selectAttributesEntitiesByCode = createSelector(selectAttributesAll, (attributes) =>
  attributes.reduce((prev, curr) => {
    prev[curr.code] = curr;
    return prev;
  }, {} as { [code: string]: Attribute })
);

export const selectSelectedEntity = createSelector(
  selectAttributesEntities,
  selectSelectedEntityId,
  (entities, selectedEntityId) => {
    if (selectedEntityId) {
      return entities[selectedEntityId];
    } else {
      return undefined;
    }
  }
);
